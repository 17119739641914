import {
  createStyles,
  lighten,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { MouseEvent, useState } from "react";
import { useIsDesktop } from "utils/hooks";
import theme from "views/Theme";

export const styles = (theme: Theme) =>
  createStyles({
    menuButton: {
      maxHeight: theme.spacing(5),
      maxWidth: theme.spacing(5),
      padding: theme.spacing(1),
      background: lighten(theme.palette.primary.dark, 0.1),
      "& img": {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    },
  });

interface IProfileProps {
  user: {
    name: string;
    image: string;
  };
  handleLogout: () => void;
  handleProfile: () => void;
  toggleGreetings?: boolean;
}

export const Profile = withStyles(styles)(
  ({
    user,
    handleLogout,
    handleProfile,
    toggleGreetings,
    classes,
  }: IProfileProps & WithStyles<typeof styles>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const isDesktop = useIsDesktop();

    const handleMenu = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          wrap="nowrap"
        >
          {user && user.name && (
            <>
              {!isDesktop ? (
                <>
                  {toggleGreetings ? (
                    <Box marginRight={theme.spacing(1 / 6)}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        noWrap
                      >
                        {`Olá, ${user.name}!`}
                      </Typography>
                    </Box>
                  ) : (
                    <Box marginRight={theme.spacing(1 / 6)}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        noWrap
                      >
                        {`Olá, ${user.name}!`}
                      </Typography>
                    </Box>
                  )}
                </>
              ) : (
                <Box marginRight={theme.spacing(1 / 6)}>
                  <Typography variant="subtitle1" color="textPrimary" noWrap>
                    {`Olá, ${user.name}!`}
                  </Typography>
                </Box>
              )}
            </>
          )}

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            className={classes.menuButton}
          >
            <Avatar alt="current user" src={user.image} />
          </IconButton>
          <Menu
            id="menu-profile"
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleLogout();
                handleClose();
              }}
            >
              {user.name ? "Sair" : "Autenticar"}
            </MenuItem>
          </Menu>
        </Grid>
      </div>
    );
  }
);
