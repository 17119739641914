import React from "react";
import Input, { InputProps } from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
// import { withStyles, WithStyles } from "@material-ui/core";
// import theme from "views/Theme";

interface IInputVariantProps extends InputProps {
  variant?: "standard" | "filled";
  // size?: "md" | "lg";
}

// const truncateStyled: React.CSSProperties = {
//   overflow: "hidden",
//   whiteSpace: "nowrap",
//   textOverflow: "ellipsis",
// };

// const styles = {
//   root: {
//     cursor: "pointer",
//     "&.Mui-disabled": {
//       cursor: "pointer",
//     },
//     "&.MuiInput-underline:hover:before": {
//       borderBottom: `2px solid ${theme.palette.text.primary}`,
//     },
//     "&.MuiInput-underline.Mui-disabled:before": {
//       borderBottomStyle: "solid",
//     },
//   },
//   input: {
//     color: theme.palette.text.primary,
//     cursor: "pointer",
//     fontStyle: "normal",
//     fontSize: 22,
//     fontWeight: 600,
//     ...truncateStyled,
//   },
// };

// TODO: Implement Styles with withStyles().
export const InputVariant = ({
  variant,
  // size,
  // classes,
  ...rest
}: IInputVariantProps) => {
  if (variant === "filled") {
    return <FilledInput {...rest} />;
  } else {
    return <Input {...rest} />;
  }
};
