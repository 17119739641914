import { withStyles, WithStyles, Theme, createStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { IDefaultSummaryVisualData } from "services/api";
import { InfoField } from "./InfoField";
import { MainField } from "./MainField";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      height: "100%",
      maxWidth: "420px",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    mainValue: {
      paddingTop: theme.spacing(1),
    },
  });

interface IVisualContentProps extends WithStyles<typeof styles> {
  content?: IDefaultSummaryVisualData["content"];
}

export const VisualContent = withStyles(styles)(
  ({ content, classes }: IVisualContentProps) => {
    return (
      <Grid container direction="row" className={classes.wrapper}>
        <Grid item container direction="column" justify="center" xs={6}>
          <InfoField
            description={content?.metricDescription}
            info={content?.metricValue}
          />
        </Grid>
        <Grid item container direction="column" justify="center" xs={6}>
          <InfoField
            description={content?.goalDescription}
            info={content?.goalValue}
          />
        </Grid>
        <Grid item xs={12} className={classes.mainValue}>
          <MainField info={content?.mainValue} />
        </Grid>
      </Grid>
    );
  }
);
