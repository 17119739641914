import { FC, useEffect, useState } from "react";
import Routes from "routes";
import { ConsoleLogger as Logger } from "utils/logger";
import * as Services from "services";
import Amplify from "aws-amplify";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectIsAuthenticated, setIsAuthenticated, setUser } from "redux/auth";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LoaderView from "views/LoaderView";
import { setIsLoaded, setReportChildren } from "redux/nav-menu";
import { fetchData } from "services/api";
import { errorMessageBuilder } from "utils/errors/helpers";
import { useFeatureFlag } from "utils/FeatureFlag";
import ErrorView from "views/ErrorView";
import { FriendlyErrorMessages } from "utils/errors/messages";
import { QueryParamProvider } from "use-query-params";

Amplify.Logger.LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || "INFO";
const logger = new Logger("Main");

const App: FC = () => {
  const dispatch = useAppDispatch();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [originalErrorMessage, setOriginalErrorMessage] = useState<string>("");
  const isAuth = useAppSelector(selectIsAuthenticated);
  const isReportViewerEnabled = useFeatureFlag("enableReportViewer");

  useEffect(() => {
    logger.info("Initializing all services...");
    let isMounted = true;
    Services.init()
      .then((data) => {
        if (isMounted) {
          const auth: Services.Auth = Services.Auth.getInstance();
          if (auth.isAuthenticated()) {
            dispatch(setIsAuthenticated());
            const cognitoUserSession = data[0];
            const {
              idToken: {
                payload: { name },
              },
            } = cognitoUserSession;
            dispatch(
              setUser({
                name: name,
                image: "/images/logo-energimp.svg",
              })
            );
          }
          logger.info("Services initialized");
          setIsInitialized(true);
        }
      })
      .catch((error) => {
        if (isMounted) {
          setHasError(true);
          setErrorMessage(errorMessageBuilder(error));
          setOriginalErrorMessage(
            error.extraData
              ? {
                  ...error.extraData,
                  message: `At <App /> inside of Services.init: ${error.extraData.message}`,
                }
              : JSON.stringify(error)
          );
        }
      });
    return () => {
      isMounted = false;
    };
  }, [dispatch]);

  if (!isInitialized && !hasError) {
    return <LoaderView open={true} />;
  }

  if (!isInitialized && hasError) {
    return (
      <ErrorView
        errorMessage={errorMessage}
        title={FriendlyErrorMessages.GENERIC_TITLE}
        errorExtraData={{ message: originalErrorMessage }}
        withDialog={true}
        handleRetry={() => window.location.reload()}
      />
    );
  }

  return (
    <>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Routes />
        </QueryParamProvider>
      </Router>
      {hasError && (
        <ErrorView
          errorMessage={errorMessage}
          title={FriendlyErrorMessages.GENERIC_TITLE}
          errorExtraData={{ message: originalErrorMessage }}
          withDialog={true}
          withCloseButton={true}
          handleRetry={() => window.location.reload()}
        />
      )}
    </>
  );
};

export default App;
