import { Grid } from "@material-ui/core";
import { ReactElement } from "react";
import {
  IO,
  IPanelItemView,
  IPanelViewGroup,
  IPanelViewLayout,
  IPanelViewOptionallyArray,
  IPanelViewOrGroup,
} from "./types";

export const buildViewTree = <T extends IO>(
  view: IPanelViewOptionallyArray<T>,
  buildLeaf: BuildLeaf<T>,
  key: number = 0
): ReactElement => {
  if (Array.isArray(view)) {
    return (
      <>
        {(view as IPanelItemView<T>[]).map((view, i) =>
          buildViewTree(view, buildLeaf, i)
        )}
      </>
    );
  } else {
    return buildViewNode(view, buildLeaf, key);
  }
};

export type BuildLeaf<T extends IO> = (
  view: IPanelItemView<T>,
  key: number
) => ReactElement;

// the presence of a "layout" key is indicative of a group
export const isLeaf = <T extends IO>(view: IPanelViewOrGroup<T>): boolean => {
  return !("layout" in view);
};

export const buildViewNode = <T extends IO>(
  view: IPanelViewOrGroup<T>,
  buildLeaf: BuildLeaf<T>,
  key: number
): ReactElement => {
  if (isLeaf(view)) {
    return buildLeaf(view as IPanelItemView<T>, key);
  } else {
    return buildViewTreeGroup(view as IPanelViewGroup<T>, buildLeaf, key);
  }
};

export const buildViewTreeGroup = <T extends IO>(
  view: IPanelViewGroup<T>,
  buildLeaf: BuildLeaf<T>,
  key: number
): ReactElement => {
  const { layout, views } = view;

  return buildViewLayout(
    layout,
    key,
    views.map((view, i) => buildViewTree(view, buildLeaf, i))
  );
};

export const buildViewLayout = (
  layout: IPanelViewLayout,
  key: number,
  children: ReactElement | ReactElement[]
): ReactElement => {
  return (
    <Grid
      key={key}
      container
      spacing={2}
      wrap={"wrap"}
      alignContent={"flex-start"}
      style={{ margin: 0, width: "100%" }}
      {...layout}
    >
      {children}
    </Grid>
  );
};
