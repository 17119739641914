import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ErrorView from "views/ErrorView";
import { FriendlyErrorMessages } from "utils/errors/messages";
import { GenericError } from "utils/errors";
import { errorMessageBuilder } from "utils/errors/helpers";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

interface IVisualErrorProps {
  error: GenericError;
  handleUpdate: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.dark,
    },
  });

export const VisualError = withStyles(styles)(
  ({
    error,
    handleUpdate,
    classes,
  }: IVisualErrorProps & WithStyles<typeof styles>) => {
    const errorExtraData = {
      message: JSON.stringify({
        message: error.message,
        details: error.extraData?.message,
      }),
      instructions: error.extraData?.instructions,
    };

    return (
      <Grid container>
        <Box padding={2} width="100%">
          <ErrorView
            title={FriendlyErrorMessages.GENERIC_TITLE}
            handleRetry={handleUpdate}
            errorMessage={errorMessageBuilder(error)}
            errorExtraData={errorExtraData}
            classes={classes}
          />
        </Box>
      </Grid>
    );
  }
);
