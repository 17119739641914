import { IPeriod } from "../types";

export enum VisualType {
  CHART = "CHART",
  COMPARISON_CHART = "COMPARISON_CHART",
  DEFAULT_SUMMARY = "DEFAULT_SUMMARY",
  COMPARISON_SUMMARY = "COMPARISON_SUMMARY",
}

interface IVisualData {
  id: string;
  type: VisualType;
  title: string[];
  subtitle: string[];
}

export interface IChartVisualData extends IVisualData {
  content: {
    plotly: string;
    config: {
      displayModeBar: boolean;
      displaylogo: boolean;
    };
  };
}

export interface IDefaultSummaryVisualData extends IVisualData {
  content: {
    metricDescription: string;
    metricValue: string;
    goalDescription: string;
    goalValue: string;
    mainValue: string;
  };
}

export interface IComparisonSummaryVisualMetricData {
  title: string;
  value: string;
  refValue: string;
  diff: string;
}

export interface IComparisonSummaryVisualData extends IVisualData {
  content: {
    metrics: IComparisonSummaryVisualMetricData[];
  };
}

export type TVisualData =
  | IChartVisualData
  | IDefaultSummaryVisualData
  | IComparisonSummaryVisualData;

export interface IGetVisualResult {
  getVisual: TVisualData;
}

interface IVisualInputBase {
  id: string;
  title?: string;
  subtitle?: string;
  assets: string[];
  periods: IPeriod[];
  metrics: string[];
}

export interface IDefaultVisualInput extends IVisualInputBase {}

export interface IComparisonVisualInput extends IVisualInputBase {
  metrics: string[];
  colors?: string[];
}

export type TVisualInput = IDefaultVisualInput | IComparisonVisualInput;

export interface IVisualInput {
  visualInput: {
    defaultVisualInput?: IDefaultVisualInput;
    comparisonVisualInput?: IComparisonVisualInput;
  };
}
