import { Theme, createStyles, fade } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontWeight: 700,
    },
    label: {
      paddingTop: 3,
    },
    containedPrimary: {
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
      "&:active": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
        opacity: 0.5,
      },
      "&:disabled": {
        color: theme.palette.text.disabled,
        backgroundColor: fade(theme.palette.secondary.main, 0.5),
        opacity: 1,
      },
    },
    containedSecondary: {
      "&:hover": {
        backgroundColor: fade(theme.palette.secondary.light, 0.3),
      },
      "&:disabled": {
        color: theme.palette.text.disabled,
        backgroundColor: fade(theme.palette.secondary.main, 0.5),
        opacity: 1,
      },
    },
  });
