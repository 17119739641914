import { memo } from "react";
import { VisualContent } from "./VisualContent";
import { IComparisonVisualInput } from "services/api";
import { BaseVisual } from "features/BaseVisual";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";

const styles = createStyles({
  visualBaseContentMobile: {
    height: "auto !important",
  },
});

interface IComparisonSummaryVisualProps
  extends Partial<IComparisonVisualInput> {
  disable?: boolean;
}

export const ComparisonSummaryVisual = withStyles(styles)(
  memo(
    ({
      id,
      assets,
      periods,
      metrics,
      disable = false,
      classes,
    }: IComparisonSummaryVisualProps & WithStyles<typeof styles>) => {
      return (
        <BaseVisual
          input={{ id, assets, periods, metrics }}
          disable={disable}
          classes={classes}
        >
          <VisualContent />
        </BaseVisual>
      );
    }
  )
);
