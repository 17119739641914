import { useState, MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { selectNavMenu } from "redux/nav-menu";
import { ConsoleLogger as Logger } from "utils/logger";
import IconButton from "@material-ui/core/IconButton";
import { LogoDiamonds } from "views/LogoView";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { styles } from "./styles";
import { useFeatureFlag } from "utils/FeatureFlag";
import CheckIcon from "@material-ui/icons/Check";
import ExpandIconView from "views/ExpandIconView";
import { withStyles, WithStyles } from "@material-ui/core";

const logger = new Logger("NavMenuContainer");

export const NavMenu = withStyles(styles)(
  ({ classes }: WithStyles<typeof styles>) => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navMenu = useAppSelector(selectNavMenu);
    const items = [navMenu.panel, navMenu.report];

    const isReportViewerEnabled = useFeatureFlag("enableReportViewer");

    const handleMenu = (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const goTo = (path: string) => {
      logger.info(`Changing to ${path}`);
      history.push(path);
      handleClose();
    };

    return (
      <>
        {isReportViewerEnabled ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={items.length > 0 ? handleMenu : undefined}
            className={classes.menuButton}
          >
            <LogoDiamonds />
            {items.length > 0 && <ExpandIconView expanded={open} />}
          </IconButton>
        ) : (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
          >
            <LogoDiamonds />
          </IconButton>
        )}
        {items.length > 0 && (
          <Menu
            id="menu-app-bar"
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            onClose={handleClose}
            classes={{ paper: classes.paper, list: classes.list }}
          >
            {items.map((item, idx) => (
              <div key={idx}>
                {Array.isArray(item.children) && (
                  <Box paddingLeft={2}>
                    <Typography
                      variant="overline"
                      color="textPrimary"
                      classes={{ overline: classes.overline }}
                      noWrap
                    >
                      {item.label}
                    </Typography>
                  </Box>
                )}
                {Array.isArray(item.children) &&
                  item.children.map((subItem) => (
                    <MenuItem
                      key={subItem.path}
                      onClick={() => {
                        goTo(`${item.path}/${subItem.path}`);
                      }}
                      selected={
                        `${item.path}/${subItem.path}` ===
                        history.location.pathname
                      }
                      className={classes.nestedItem}
                    >
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        classes={{ body1: classes.body1 }}
                        noWrap
                      >
                        {subItem.label}
                      </Typography>
                      <div className="check-icon">
                        <CheckIcon />
                      </div>
                    </MenuItem>
                  ))}
              </div>
            ))}
          </Menu>
        )}
      </>
    );
  }
);
