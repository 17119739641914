import React, { ReactElement } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import UnauthRoute from "./UnauthenticatedRoute";
import AuthRoute from "./AuthenticatedRoute";
import { useAppSelector } from "redux/hooks";
import { selectIsAuthenticated } from "redux/auth";
import SignInScene from "scenes/SignInScene";
import { PanelsScene } from "scenes/PanelsScene/PanelsScene";

const Routes: React.FC = (): ReactElement => {
  const isAuth: boolean = useAppSelector(selectIsAuthenticated);

  return (
    <Switch>
      <UnauthRoute exact path="/sign-in" redirect="/" isAuthenticated={isAuth}>
        <SignInScene />
      </UnauthRoute>
      <AuthRoute exact path="/" redirect="/sign-in" isAuthenticated={isAuth}>
        <Redirect to="/panel" />
      </AuthRoute>
      <AuthRoute path="/panel" redirect="/sign-in" isAuthenticated={isAuth}>
        <PanelsScene />
      </AuthRoute>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
