import { PlotParams } from "react-plotly.js";
import applyDefaultProps from "./applyDefaultProps";

export const handleMobileProps = (
  figure: PlotParams,
  config: Partial<Plotly.Config>
) => {
  const plot = applyDefaultProps(figure, config);
  const tickFontSize: number = 10;
  plot.figure.layout = {
    ...plot.figure.layout,
    showlegend: false,
    margin: {
      ...plot.figure.layout.margin,
      t: 8,
      l: 38,
      r: 42,
      b: 44,
    },
    xaxis: {
      ...plot.figure.layout.xaxis,
      tickfont: {
        ...plot.figure.layout.xaxis?.tickfont,
        size: tickFontSize,
      },
    },
    yaxis: {
      ...plot.figure.layout.yaxis,
      tickfont: {
        ...plot.figure.layout.yaxis?.tickfont,
        size: tickFontSize,
      },
    },
    yaxis2: {
      ...plot.figure.layout.yaxis2,
      tickfont: {
        ...plot.figure.layout.yaxis2?.tickfont,
        size: tickFontSize,
      },
    },
  };

  return plot;
};

export default handleMobileProps;
