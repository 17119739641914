import { FC, useState, useRef } from "react";
import Card, { CardProps } from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { useStyles } from "./styles";
import { IErrorViewProps } from "./types";
import selectContent from "utils/selectContent";
import ExpandIconView from "views/ExpandIconView";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Wrapper from "./Wrapper";
import theme from "views/Theme";

const ErrorView: FC<IErrorViewProps & CardProps> = ({
  title,
  subtitle,
  errorMessage,
  errorExtraData,
  withDialog = false,
  withCloseButton = false,
  handleRetry,
  classes,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const codeRef = useRef<HTMLElement>(null);
  const internalClasses = useStyles();

  const handleCopy = () => {
    if (codeRef.current) {
      selectContent(codeRef.current);
      const copied = document.execCommand("copy");
      setCopied(copied);
    }
  };

  const handleCloseSnackbar = () => {
    setCopied(false);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <Wrapper
      open={open}
      withDialog={withDialog}
      onClose={withCloseButton ? handleOnClose : undefined}
    >
      <Card elevation={0} classes={classes} style={{ overflow: "unset" }}>
        <CardHeader
          title={title}
          subheader={subtitle}
          classes={{ root: internalClasses.cardHeader }}
          action={
            withCloseButton ? (
              <IconButton
                aria-label="close"
                onClick={handleOnClose}
                className={internalClasses.closeButton}
              >
                <CloseIcon />
              </IconButton>
            ) : undefined
          }
        />
        <CardContent classes={{ root: internalClasses.cardContent }}>
          <Typography variant="body1" component="p">
            {errorMessage}
          </Typography>
          {errorExtraData && errorExtraData.instructions && (
            <>
              <Alert
                severity="info"
                variant="standard"
                classes={{ root: internalClasses.info }}
              >
                Instruções
                <ul
                  style={{
                    margin: `${theme.spacing(1)}px 0 0`,
                    paddingInlineStart: 24,
                  }}
                >
                  {errorExtraData.instructions.map(
                    (inst: string, idx: number) => (
                      <li key={idx}>{inst}</li>
                    )
                  )}
                </ul>
              </Alert>
              <Box paddingTop={2}>
                <Typography variant="body2" color="textSecondary" component="p">
                  Caso queira visualizar mais detalhes sobre o erro, você pode
                  copiá-lo clicando logo abaixo em "Ver detalhes".
                </Typography>
              </Box>
            </>
          )}
        </CardContent>
        <CardActions
          disableSpacing
          classes={{ root: internalClasses.cardActions }}
        >
          {errorExtraData && errorExtraData.message && (
            <Button
              onClick={() => setExpanded(!expanded)}
              variant="text"
              size="small"
              startIcon={<ExpandIconView expanded={expanded} />}
            >
              Ver detalhes
            </Button>
          )}
          <Button
            className={internalClasses.button}
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleRetry}
          >
            Retentar
          </Button>
        </CardActions>
        {errorExtraData && errorExtraData.message && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent classes={{ root: internalClasses.codeWrapper }}>
              <code
                className={internalClasses.code}
                onClick={() => handleCopy()}
                ref={codeRef}
                style={{
                  wordBreak: "break-word",
                  paddingRight: `${theme.spacing(3)}px`,
                }}
              >
                {errorExtraData.message}
                <Tooltip title="Copiar">
                  <FileCopyOutlinedIcon
                    className={internalClasses.copyIcon}
                    color="disabled"
                  />
                </Tooltip>
              </code>
            </CardContent>
          </Collapse>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={copied}
          onClose={() => handleCloseSnackbar()}
          autoHideDuration={2000}
        >
          <Alert
            elevation={6}
            variant="standard"
            onClose={() => handleCloseSnackbar()}
            severity="success"
          >
            Copiado para a área de transferência
          </Alert>
        </Snackbar>
      </Card>
    </Wrapper>
  );
};

export default ErrorView;
