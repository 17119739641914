import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/types";
import { IAuthState } from "./types";

const initialState: IAuthState = {
  isAuthenticated: false,
  user: {
    name: "",
    image: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
    setIsNotAuthenticated: (state) => {
      state.isAuthenticated = false;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const {
  setIsAuthenticated,
  setIsNotAuthenticated,
  setUser,
} = authSlice.actions;

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;
export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
