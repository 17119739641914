import { FC } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100vw",
      minWidth: "320px",
      maxHeight: "100vh",
    },
  })
);

const FullScreenView: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid
      id="full-screen-view"
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
      classes={classes}
    >
      {children}
    </Grid>
  );
};

export default FullScreenView;
