import {
  AccessDeniedError,
  InternalServerError,
  InternetConnectionError,
  NetworkError,
  ClientError,
  APIInternalError,
  EmailOrPasswordInvalidError,
} from "./errors";
import { FriendlyErrorMessages } from "./messages";

export function errorMessageBuilder(error: Error) {
  if (error instanceof AccessDeniedError) {
    return FriendlyErrorMessages.ACCESS_DENIED;
  } else if (error instanceof ClientError) {
    return FriendlyErrorMessages.CLIENT;
  } else if (error instanceof InternalServerError) {
    return FriendlyErrorMessages.INTERNAL_SERVER;
  } else if (error instanceof APIInternalError) {
    return FriendlyErrorMessages.API_INTERNAL_ERROR;
  } else if (error instanceof InternetConnectionError) {
    return FriendlyErrorMessages.INTERNET_CONNECTION;
  } else if (error instanceof NetworkError) {
    return FriendlyErrorMessages.NETWORK;
  } else if (error instanceof EmailOrPasswordInvalidError) {
    return FriendlyErrorMessages.EMAIL_OR_PASSWORD_INVALID;
  } else {
    return FriendlyErrorMessages.GENERIC;
  }
}
