import { makeStyles, Theme, createStyles, fade } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listBox: {
      "& li": {
        color: theme.palette.text.secondary,
        borderRadius: theme.shape.borderRadius,
        cursor: "pointer",
        transition: theme.transitions.create(["background-color"], {
          duration: theme.transitions.duration.shortest,
        }),
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        "&[aria-selected=true]": {
          color: theme.palette.text.primary,
          "&:before": {
            content: `"\\e876"`,
            fontFamily: "Material Icons",
            fontSize: theme.typography.button.fontSize,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: theme.spacing(5),
            minWidth: theme.spacing(3),
            position: "absolute",
            right: 0,
          },
        },
        "&[data-focus=true]": {
          backgroundColor: theme.palette.action.focus,
        },
      },
    },
    formControlRoot: {
      "& .MuiInput-underline:before": {
        borderBottomColor: fade(theme.palette.primary.main, 0.2),
      },
    },
  })
);
