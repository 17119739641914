import { AttemptContext, AttemptOptions } from "@lifeomic/attempt";
import {
  APIInternalError,
  EmptyDatasetError,
  GenericError,
  InternalServerError,
  InternetConnectionError,
  NetworkError,
} from "utils/errors";
import { GraphQLError } from "graphql";
import { FriendlyErrorInstructions } from "utils/errors/messages";

export interface AmplifyGraphQLError extends Error {
  data: {};
  errors: ({
    errorInfo?: any;
    errorType?: string;
  } & GraphQLError)[];
}

const getStatusCode = (msg: string): Number | boolean => {
  // https://github.com/axios/axios/blob/d99d5faac29899eba68ce671e6b3cbc9832e9ad8/lib/core/settle.js#L18
  // String returned by Axios library: 'Request failed with status code ' + response.status
  const regex = /Request failed with status code [0-9]{3}$/;
  if (!regex.test(msg)) {
    return false;
  }
  const statusCode = msg.substr(-3);
  return Number(statusCode);
};

export const parseError = (error: AmplifyGraphQLError): Error => {
  const message = error.errors[0].message;
  const errorType = error.errors[0].errorType;

  if (errorType === "InternalError") {
    return new APIInternalError(
      message,
      FriendlyErrorInstructions.apiInternalError
    );
  } else if (getStatusCode(message) >= 500 && getStatusCode(message) <= 599) {
    return new InternalServerError(
      message,
      FriendlyErrorInstructions.internalServer
    );
  } else if (errorType === "EmptyDatasetError") {
    return new EmptyDatasetError(message);
  } else {
    return new GenericError({
      message: `Error type: ${errorType}. Message: ${message}`,
      instructions: FriendlyErrorInstructions.generic,
    });
  }
};

const isAttemptableError = (errorInstance: Error) => {
  return (
    errorInstance instanceof InternalServerError ||
    errorInstance instanceof NetworkError ||
    errorInstance instanceof InternetConnectionError
  );
};

const handleError = (error: AmplifyGraphQLError, context: AttemptContext) => {
  const errorInstance = parseError(error);
  if (errorInstance && !isAttemptableError(errorInstance)) {
    context.abort();
  }
};

export const retryOptions: AttemptOptions<object> = {
  delay: 500,
  maxAttempts: 5,
  minDelay: 250,
  factor: 2,
  jitter: true,
  handleError,
  initialDelay: 0,
  maxDelay: 0,
  timeout: 0,
  handleTimeout: null,
  beforeAttempt: null,
  calculateDelay: null,
};

export function isMutation(operation: string): boolean {
  const withoutBreaksWithTrim = operation.replace(/(\r\n|\n|\r)/gm, "").trim();
  return withoutBreaksWithTrim.startsWith("mutation ");
}
