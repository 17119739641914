import { FC } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { IRouteProps } from "./types";
import { ConsoleLogger as Logger } from "utils/logger";

const logger = new Logger("AuthRoute");

const AuthenticatedRoute: FC<IRouteProps> = ({
  redirect,
  isAuthenticated,
  children,
  ...rest
}) => {
  const location = useLocation<Location>();

  if (isAuthenticated) {
    logger.debug(
      `Current location: ${location.pathname}. User is authenticated`
    );
  } else {
    logger.debug(
      `Current location: ${location.pathname}. User is not authenticated.`,
      `Redirecting to ${redirect}`
    );
  }

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            ...location,
            pathname: redirect,
            state: {
              ...location.state,
              referrer: location.pathname,
            },
          }}
        />
      )}
    </Route>
  );
};

export default AuthenticatedRoute;
