import getFontFamily from "./getFontFamily";

let canvasContext: any = null;
const getCanvasContext = (
  fontSize: number = 10,
  fontFamily: string = "nunito sans"
) => {
  const contextFont = `${fontSize}px ${getFontFamily(fontFamily)}`;

  if (!canvasContext) {
    const context = document.createElement("canvas").getContext("2d");
    if (context) {
      context.font = contextFont;
      canvasContext = context;
    }
  } else if (canvasContext.font !== contextFont) {
    canvasContext.font = contextFont;
  }

  return canvasContext;
};

export default getCanvasContext;
