import { FC, CSSProperties, useState, useRef } from "react";
import Plotly from "utils/custom-plotly";
import createPlotlyComponent from "react-plotly.js/factory";
import { Figure, PlotParams } from "react-plotly.js";
import { formatFigure, initFigure, searchBestSkipValue } from "./utils";
import { ConsoleLogger as Logger } from "utils/logger";
import { IPlotlyProps } from "./types";

const PlotlyComponent: React.ComponentType<PlotParams> = createPlotlyComponent(
  Plotly
);

const PlotlyComponentHandler: FC<
  IPlotlyProps & {
    className?: string;
    style?: CSSProperties;
  }
> = ({ plotly, config, className, style }) => {
  const title = plotly.data[4]?.name || "";
  const logger = new Logger(`${title.replace(/ /g, "")}Visual`);

  const [formattedFigure, setFormattedFigure] = useState<PlotParams>(
    initFigure(plotly, logger)
  );
  const skip = useRef<number>(1);
  const prevSvgWidth = useRef<number>(Infinity);
  const prevPlotWidth = useRef<number>(Infinity);

  // Increases or decreases the number of skips for the labels of a graph,
  // according to its width
  const handleUpdate = (
    figure: Readonly<Figure>,
    graphDiv: Readonly<HTMLElement>
  ) => {
    // Prevent unnecessary update and update prevSvgWidth if necessary.
    const svg = graphDiv.getElementsByClassName("main-svg")[0];
    const currSvgWidth = svg.getBoundingClientRect().width;
    logger.debug(
      `SVG width: { previous: ${prevSvgWidth.current.toFixed(
        2
      )}, current: ${currSvgWidth.toFixed(2)} }`
    );
    if (currSvgWidth === 0) {
      logger.debug(`SVG width is zero. Skipping update.`);
      return;
    }
    if (currSvgWidth === prevSvgWidth.current) {
      logger.debug(`SVG width did not change. Skipping update.`);
      return;
    }
    prevSvgWidth.current = currSvgWidth;

    const plot = graphDiv.getElementsByClassName("overplot")[0];
    const currPlotWidth = plot.getBoundingClientRect().width;
    logger.debug(
      `Plot width: { previous: ${prevPlotWidth.current.toFixed(
        2
      )}, current: ${currPlotWidth.toFixed(2)} }`
    );

    let bestSkip;

    if (currPlotWidth > prevPlotWidth.current) {
      logger.debug(
        `Attempting to decrease the number of skips. Current skip: ${skip.current}`
      );
      bestSkip = searchBestSkipValue(
        formattedFigure,
        currPlotWidth,
        skip.current,
        false,
        null,
        logger
      );
    } else if (currPlotWidth < prevPlotWidth.current) {
      logger.debug(
        `Attempting to increase the number of skips. Current skip: ${skip.current}`
      );
      bestSkip = searchBestSkipValue(
        formattedFigure,
        currPlotWidth,
        skip.current,
        true,
        null,
        logger
      );
    }
    prevPlotWidth.current = currPlotWidth;

    if (bestSkip && bestSkip !== skip.current) {
      logger.debug(`Updating skip from ${skip.current} to ${bestSkip}`);
      skip.current = bestSkip;
      setFormattedFigure(formatFigure(formattedFigure, bestSkip));
    } else {
      logger.debug("Cound not find a better number of skips");
    }
  };

  return (
    <PlotlyComponent
      {...formattedFigure}
      config={{ ...config, showTips: false }}
      onInitialized={handleUpdate}
      onUpdate={handleUpdate}
      onLegendClick={() => false}
      useResizeHandler={true}
      className={className}
      style={style}
    />
  );
};

export default PlotlyComponentHandler;
