import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  AccessDeniedError,
  ClientError,
  GenericError,
  InternalServerError,
  InternalServerErrorAttemptable,
  InternetConnectionError,
  NetworkError,
} from "utils/errors";
import { chartB64FullData, summaryB64FullData } from "./mocks";
import { ConsoleLogger as Logger } from "utils/logger";
import { FriendlyErrorInstructions } from "utils/errors/messages";

const logger = new Logger("HttpClient");

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor() {
    this.instance = axios.create({
      timeout: 9000,
    });

    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  protected _handleError = (error: any) => {
    const SAFE_HTTP_METHODS = ["get", "head", "options"];
    const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat(["put", "delete"]);

    if (!error.config) {
      // Cannot determine if the request can be retried
      logger.info("Error", error);
      throw new GenericError(
        {
          message: error.message,
          instructions: FriendlyErrorInstructions.generic,
        },
        "At HttpClient._handleError: Unrecognizable request."
      );
    } else if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      logger.info(error.response.data);
      logger.info(error.response.status);
      logger.info(error.response.headers);
      if (error.response.status >= 500 && error.response.status <= 599) {
        if (IDEMPOTENT_HTTP_METHODS.includes(error.config.method)) {
          throw new InternalServerErrorAttemptable(
            error.message,
            FriendlyErrorInstructions.internalServer
          );
        } else {
          throw new InternalServerError(
            error.message,
            FriendlyErrorInstructions.internalServer
          );
        }
      } else if (error.response.status >= 400 && error.response.status <= 499) {
        if (error.response.status === 403) {
          throw new AccessDeniedError(
            error.message,
            FriendlyErrorInstructions.accessDenied
          );
        } else {
          throw new ClientError(
            error.message,
            FriendlyErrorInstructions.client
          );
        }
      } else {
        throw new GenericError(
          {
            message: `${error.code}: ${error.message}`,
            instructions: FriendlyErrorInstructions.generic,
          },
          "At HttpClient._handleError: Request with error out of ranges 5xx and 4xx."
        );
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      logger.info(error.request);
      if (navigator.onLine) {
        throw new NetworkError(
          error.message,
          FriendlyErrorInstructions.network
        );
      } else {
        throw new InternetConnectionError(
          error.message,
          FriendlyErrorInstructions.internetConnection
        );
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      logger.info("Error", error.message);
      throw new GenericError(
        {
          message: `${error.code}: ${error.message}`,
          instructions: FriendlyErrorInstructions.generic,
        },
        "At HttpClient._handleError: Request error."
      );
    }
  };

  public getMock(fileName: string, presignedUrl?: boolean) {
    return new Promise<any>((resolve) => {
      if (fileName === "titles.json.gz") {
        setTimeout(() => {
          resolve('["summary_0.json.gz","chart_1.json.gz"]');
        }, 1000);
      } else if (fileName.includes("_0.json.gz")) {
        setTimeout(() => {
          resolve(summaryB64FullData);
        }, 1000);
      } else {
        setTimeout(() => {
          resolve(chartB64FullData);
        }, 1500);
      }
    });
  }
}

export default HttpClient;
