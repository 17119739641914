import { AttemptContext, AttemptOptions } from "@lifeomic/attempt";
import {
  InternalServerErrorAttemptable,
  InternetConnectionError,
  NetworkError,
} from "utils/errors";

function storageHandleError(error: any) {
  return (
    error instanceof InternalServerErrorAttemptable ||
    error instanceof NetworkError ||
    error instanceof InternetConnectionError
  );
}

export const retryOptions: AttemptOptions<object> = {
  delay: 1000,
  maxAttempts: 5,
  minDelay: 250,
  factor: 2,
  jitter: true,
  handleError(error: any, context: AttemptContext) {
    if (error && !storageHandleError(error)) {
      context.abort();
    }
  },
  initialDelay: 0,
  maxDelay: 1000,
  timeout: 0,
  handleTimeout: null,
  beforeAttempt: null,
  calculateDelay: null,
};
