import config from "./config";
import Auth from "./auth";
import Storage from "./storage";
import Amplify from "aws-amplify";
import { ConsoleLogger as Logger } from "utils/logger";

const logger = new Logger("Service");

const services = [Auth, Storage];

export const init = async (): Promise<any> => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.region,
      userPoolId: config.auth.userPoolId,
      identityPoolId: config.auth.identityPoolId,
      userPoolWebClientId: config.auth.clientId,
    },
    Storage: {
      AWSS3: {
        level: "private",
        bucket: config.storage.bucket,
        region: config.region,
        expiration: 10,
        download: false,
      },
    },
    aws_appsync_graphqlEndpoint: config.api.url,
    aws_appsync_authenticationType: config.api.authType,
  });

  return Promise.all(
    services.map((service) => {
      const instance = service.getInstance();
      logger.info(`Initializing ${instance.constructor.name} service...`);
      return instance.init();
    })
  );
};

export { Auth, Storage };
