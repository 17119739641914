import { fetchData } from "../shared";
import { IGetPanelResult, TPanelData } from "./types";
import mockedGetPanel from "./__mocks__/getPanel";

const query = /* GraphQL */ `
  query GetPanel($panelId: ID!) {
    getPanel(panelId: $panelId) {
      id
      type
      label
      assets {
        ...assetFields
        children {
          ...assetFields
          children {
            ...assetFields
          }
        }
      }
      ... on GenerationPanel {
        periods {
          ...periodsFields
        }
        visualList {
          id
          type
        }
      }
      ... on ComparisonPanel {
        primaryPeriods {
          ...periodsFields
        }
        secondaryPeriods {
          ...periodsFields
        }
        metricList {
          id
          label
        }
        visualList {
          id
          type
        }
      }
    }
  }

  fragment periodsFields on PanelPeriod {
    id
    label
    startLabel
    endLabel
    startIso
    endIso
    freq
  }

  fragment assetFields on AssetNode {
    id
    label
    type
  }
`;

export const getPanel = async <T extends TPanelData>(
  id: string
): Promise<T | undefined> => {
  const result = await fetchData<IGetPanelResult>(query, { panelId: id });
  return result.data?.getPanel as T | undefined;
};

export default process.env.REACT_APP_MOCKED ? mockedGetPanel : getPanel;
