import {
  memo,
  useEffect,
  useState,
  cloneElement,
  ReactElement,
  Fragment,
} from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./styles";
import { getVisual, TVisualData, TVisualInput } from "services/api";
import { VisualContent } from "./VisualContent";
import { Dialog } from "features/Dialog";
import { VisualError } from "./VisualError";
import { EmptyDatasetError, GenericError } from "utils/errors";
import { PartialKey } from "utils/types";
import { ConsoleLogger as Logger } from "utils/logger";
import { VisualEmpty } from "./VisualEmpty";

const logger = new Logger("VisualError");

type TBaseVisualProps<I extends TVisualInput, D extends TVisualData> = {
  input: Partial<I>;
  children: ReactElement<{ content: D["content"]; disable?: boolean }>;
  className?: string;
  enableDialog?: boolean;
  disable?: boolean;
} & PartialKey<WithStyles<typeof styles>, "classes">;

const WrappedBaseVisual = <I extends TVisualInput, D extends TVisualData>({
  input,
  children,
  classes,
  className,
  enableDialog = false,
  disable,
}: TBaseVisualProps<I, D>) => {
  const [error, setError] = useState<GenericError>();
  const [data, setData] = useState<D>();
  const [retryCounter, setRetryCounter] = useState<number>(0);

  useEffect(() => {
    let isMounted = true;
    setError(undefined);

    if (input && Object.values(input).every((value) => value)) {
      setData(undefined);
      getVisual<I, D>(input as I)
        .then((data) => {
          if (isMounted && data) {
            setData(data);
          }
        })
        .catch((error) => {
          if (isMounted) {
            logger.error("Error during API request:", {
              error: error,
              input: input,
            });
            setError(error);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [input, retryCounter]);

  const handleUpdate = () => {
    setRetryCounter((prev) => prev + 1);
  };

  const ContainerComponent =
    data && enableDialog && !disable ? Dialog : Fragment;

  return error && !(error instanceof EmptyDatasetError) ? (
    <VisualContent hideHeader>
      <VisualError error={error} handleUpdate={handleUpdate} />
    </VisualContent>
  ) : (
    <ContainerComponent>
      <VisualContent
        title={data?.title}
        subtitle={data?.subtitle}
        hideHeader={Boolean(error)}
        disable={disable}
        className={className}
        classes={{
          rootNormal: classes?.visualBaseRootNormal,
          rootExpanded: classes?.visualBaseRootExpanded,
          contentDesktop: classes?.visualBaseContentDesktop,
          contentMobile: classes?.visualBaseContentMobile,
          contentExpanded: classes?.visualBaseContentExpanded,
        }}
      >
        {error && error instanceof EmptyDatasetError ? (
          <VisualEmpty />
        ) : (
          cloneElement(children, { content: data?.content, disable })
        )}
      </VisualContent>
    </ContainerComponent>
  );
};

export const BaseVisual = withStyles(styles)(
  memo(WrappedBaseVisual) as typeof WrappedBaseVisual
) as typeof WrappedBaseVisual;
