import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import SkeletonTypographyView from "views/SkeletonTypographyView";
import { styles } from "./styles";
import { withStyles, WithStyles } from "@material-ui/core";
import theme from "views/Theme";

interface IVisualContentLoader {
  disable?: boolean;
}

export const VisualContentLoader = withStyles(styles)(
  ({
    disable = false,
    classes,
  }: IVisualContentLoader & WithStyles<typeof styles>) => {
    return (
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px` }}
        >
          <Grid item>
            <SkeletonTypographyView
              width={130}
              variant="subtitle1"
              justify="center"
              animation={!disable && "wave"}
              disable={disable}
              className={classes.subtitle1}
            />
          </Grid>
          <Grid item xs>
            <Divider
              style={{ backgroundColor: theme.palette.background.paper }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          style={{ padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px` }}
        >
          <Grid item container xs={6} alignItems="center">
            <Grid item>
              <SkeletonTypographyView
                width={80}
                variant="body1"
                justify="center"
                disable={disable}
                animation={!disable && "wave"}
                className={classes.body1}
              />
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <SkeletonTypographyView
              width={80}
              variant="body1"
              justify="flex-end"
              disable={disable}
              animation={!disable && "wave"}
              className={classes.body1}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
