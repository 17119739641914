import { GraphQLResult } from "@aws-amplify/api";
import { TPanelData, IGetPanelResult } from "../types";
import generationPanel from "./generation-panel.json";
import comparisonPanel from "./comparison-panel.json";

const panels: {
  [key: string]: GraphQLResult<IGetPanelResult>;
} = {
  "0": generationPanel as GraphQLResult<IGetPanelResult>,
  "1": comparisonPanel as GraphQLResult<IGetPanelResult>,
};

const getPanel = async <T extends TPanelData>(
  id: string
): Promise<T | undefined> => {
  return Promise.resolve(panels[id].data?.getPanel) as Promise<T | undefined>;
};

export default getPanel;
