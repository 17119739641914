import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      padding: 0,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: theme.palette.grey[500],
      zIndex: 1,
    },
  });

interface IDialogTitleProps {
  onClose: () => void;
}

export const DialogTitle = withStyles(styles)(
  ({
    onClose,
    classes,
  }: IDialogTitleProps & WithStyles<typeof styles>) => {
    return (
      <MuiDialogTitle className={classes.dialogTitle}>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="medium"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }
);
