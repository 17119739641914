import {
  useState,
  cloneElement,
  FC,
  ReactElement,
  useEffect,
  Fragment,
} from "react";
import MuiDialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import Box from "@material-ui/core/Box";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import clsx from "clsx";
import { DialogTitle } from "./DialogTitle";
import { IIsDesktopComponentWrapperProps, IDialogProps } from "./types";
import { styles } from "./styles";
import { Slide, withStyles } from "@material-ui/core";
import { useIsDesktop } from "utils/hooks";
import { ElementType } from "react-router/node_modules/@types/react";

const TooltipWrapper: FC<IIsDesktopComponentWrapperProps<TooltipProps>> = (
  props
) => {
  const isDesktop = useIsDesktop();

  if (!isDesktop) {
    return <Fragment>{props.extraParams.children}</Fragment>;
  }

  return (
    <props.IsDesktopComponent {...props.extraParams}>
      {props.extraParams.children as ReactElement}
    </props.IsDesktopComponent>
  );
};

export const Dialog = withStyles(styles)(
  ({
    children,
    classes,
    className,
    fullScreen = true,
    maxWidth = "lg",
    fullWidth = true,
    disabledContainerId = undefined,
    scroll = "paper",
  }: IDialogProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const isDesktop = useIsDesktop();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
      let timer: null | NodeJS.Timeout;

      // Improve Mobile performance when rotating the screen
      if (!isDesktop && disabledContainerId) {
        const disabledEl = document.getElementById(
          disabledContainerId
        ) as HTMLElement;

        if (disabledEl) {
          if (open) {
            timer = setTimeout(() => {
              disabledEl.style.display = "none";
            }, 300);
          } else {
            disabledEl.style.display = "flex";
            // We should dispatch a resize event to trigger plotly's label
            // overlapping algorithm
            window.dispatchEvent(new Event("resize"));
          }
        }
      }
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }, [open, isDesktop, disabledContainerId]);

    return (
      <div className={clsx(classes.root, className)}>
        <div className={classes.container}>
          <div className={classes.content}>{children}</div>
          <Box className={classes.zoomBox}>
            <TooltipWrapper
              IsDesktopComponent={Tooltip as ElementType<Partial<TooltipProps>>}
              extraParams={{
                title: "Ampliar",
                children: (
                  <IconButton
                    aria-label="maximize"
                    size="small"
                    onClick={handleClickOpen}
                    className={classes.zoomIconButton}
                  >
                    <ZoomOutMapIcon className={classes.zoomIcon} />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <MuiDialog
            classes={{ paper: classes.paper }}
            fullScreen={fullScreen}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            TransitionComponent={Slide}
            scroll={scroll}
          >
            <DialogTitle onClose={handleClose} />
            <DialogContent className={classes.dialogContent}>
              {cloneElement(children as ReactElement<any>, { expanded: open })}
            </DialogContent>
          </MuiDialog>
        </div>
      </div>
    );
  }
);
