import { ReactNode } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { desktopBreakpoint } from "utils/hooks/useIsDesktop";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) => {
  console.log("###", theme.breakpoints.up(desktopBreakpoint));
  return createStyles({
    appBarRoot: {
      boxShadow: "0 10px 8px rgb(0 0 0 / 20%)",
    },
    appBarColorDefault: {
      backgroundColor: theme.palette.divider,
    },
    toolbarGutters: {
      paddingLeft: 0,
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up(desktopBreakpoint)]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  });
};

interface PanelToolbarProps extends WithStyles<typeof styles> {
  children: ReactNode;
}

export const PanelToolbar = withStyles(styles)(
  ({ children, classes }: PanelToolbarProps) => {
    return (
      <AppBar
        position="static"
        classes={{
          root: classes.appBarRoot,
          colorDefault: classes.appBarColorDefault,
        }}
        elevation={8}
        color="default"
      >
        <Toolbar variant="dense" classes={{ gutters: classes.toolbarGutters }}>
          {children}
        </Toolbar>
      </AppBar>
    );
  }
);
