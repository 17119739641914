import { Grid, GridProps, withStyles, WithStyles } from "@material-ui/core";
import { TPanelFormChildrenProps } from "features/BasePanel";
import { ComparisonChartVisual } from "features/ComparisonChartVisual";
import { ComparisonSummaryVisual } from "features/ComparisonSummaryVisual";
import { DefaultChartVisual } from "features/DefaultChartVisual";
import { DefaultSummaryVisual } from "features/DefaultSummaryVisual";
import { Select } from "features/Inputs/Select";
import { indexBy, omit, prop } from "ramda";
import React, { JSXElementConstructor, ReactElement, useState } from "react";
import { IPanelPeriod, TPanelData, VisualType } from "services/api";
import { getNodeById } from "utils/tree";
import { buildViewLayout, buildViewTree } from "./build";
import { styles } from "./styles";
import {
  IPanel,
  IPanelContext,
  IPanelContextSelected,
  IPanelData,
  IPanelViewInput,
  IPanelViewItemType,
  IPanelViewOutput,
} from "./types";

export const upperFirst = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const DEFAULT_NAME_FOR = {
  asset: "ativo",
  metric: "métrica",
  period: "período",
};

const DEFAULT_NAME_GENDER = {
  asset: true,
  metric: false,
  period: true,
};

const DEFAULT_NAME_PREFIX = [
  "Primeir",
  "Segund",
  "Terceir",
  "Quart",
  "Quint",
  "Sext",
];

const METRIC_TO_DEFAULT_SUMMARY_ID: { [key: string]: string } = {
  net_active_energy: "0",
  net_active_energy_over_ppa: "1",
  net_active_energy_over_estimativa: "2",
};

const METRIC_TO_CHART_ID: { [key: string]: string } = {
  net_active_energy: "3",
  net_active_energy_over_ppa: "4",
  net_active_energy_over_estimativa: "5",
  net_active_energy_over_cap_active_energy: "6",
  avg_wind_speed_hub: "7",
};

export const getDefaultSelectName = (
  type: IPanelViewItemType,
  i: number,
  total: number
) => {
  let name = upperFirst(DEFAULT_NAME_FOR[type]);

  if (total > 1) {
    const prefix = DEFAULT_NAME_PREFIX[i] ?? "Outr";
    const gender = DEFAULT_NAME_GENDER[type];

    name = `${prefix}${gender ? "o" : "a"} ${name}`;
  }

  return name;
};

export const Item = ({
  children,
  ...rest
}: { children: React.ReactNode } & GridProps) => (
  <Grid item xs={12} sm={12} {...rest}>
    {children}
  </Grid>
);

export const Panel = withStyles(styles)(
  ({
    data,
    spec,
    classes,
  }: WithStyles<typeof styles> & {
    spec: IPanel;
    data: IPanelData;
  }) => {
    let {
      view: { layout = {}, inputs = [], outputs = [] },
    } = spec;

    outputs = Array.isArray(outputs)
      ? outputs
      : ([outputs] as IPanelViewOutput[]);
    inputs = Array.isArray(inputs) ? inputs : ([inputs] as IPanelViewInput[]);

    const [context, setContext] = useState<IPanelContext>(spec.context);

    const setSelectedEntry = (
      type: IPanelViewItemType,
      i: number,
      id: string
    ) => {
      const typeKey: keyof IPanelContextSelected = `${type}s` as keyof IPanelContextSelected;

      const nextContext = {
        ...context,
        selected: {
          ...context.selected,
          [typeKey]: ((context.selected ?? {})[typeKey] ?? []).map(
            (selectedId, selectedIndex) => {
              return selectedIndex === i ? id : selectedId;
            }
          ),
        },
      };

      setContext(nextContext);
    };

    const setSelectedAsset = (i: number, asset: string) =>
      setSelectedEntry("asset", i, asset);
    const setSelectedPeriod = (i: number, period: string) =>
      setSelectedEntry("period", i, period);
    const setSelectedMetric = (i: number, metric: string) =>
      setSelectedEntry("metric", i, metric);

    const { assets = [], periods = [], metrics = [] } = data;

    const periodMap = indexBy(prop("id"), periods);
    const metricMap = indexBy(prop("id"), metrics);

    const firstAsset = assets[0];

    const selectedAssets = context.selected?.assets?.map(
      (asset) => getNodeById(firstAsset, asset)!
    )!;
    const selectedPeriods = context.selected?.periods?.map(
      (periodId) => periodMap[periodId]!
    )!;
    const selectedMetrics = context.selected?.metrics?.map(
      (metricId) => metricMap[metricId]!
    )!;

    const _selectedPeriods = selectedPeriods.map(
      omit(["startLabel", "endLabel"])
    );

    const buildInput = (view: IPanelViewInput, i: number): ReactElement => {
      const { type } = view;

      const typeKey: keyof IPanelContextSelected = `${type}s` as keyof IPanelContextSelected;

      const title = `Selecione o ${DEFAULT_NAME_FOR[type]}`;

      const pickEntry = <T extends any>(
        type: IPanelViewItemType,
        typeToEntry: Record<IPanelViewItemType, T>
      ) => {
        return typeToEntry[type];
      };

      const data = pickEntry(type, {
        asset: assets,
        period: periods,
        metric: metrics,
      });

      const values = pickEntry(type, {
        asset: selectedAssets,
        period: selectedPeriods,
        metric: selectedMetrics,
      });

      const status = "enabled";
      const searchLabel = "Pesquisar";

      const makeOnChange = (i: number, type: IPanelViewItemType) => (value: {
        id: string;
      }) => {
        const { id } = value;

        pickEntry(type, {
          asset: () => setSelectedAsset(i, id),
          metric: () => setSelectedMetric(i, id),
          period: () => setSelectedPeriod(i, id),
        })();
      };

      return (
        <>
          {(context.selected ?? {})[typeKey]!.map((id, i, array) => {
            const name = getDefaultSelectName(type, i, array.length);

            const value = values[i];

            const onChange = makeOnChange(i, type);

            return (
              <Item key={i} {...view.grid}>
                <Select
                  id={type}
                  status={status}
                  value={value}
                  label={`${name}:`}
                  title={`${title}`}
                  searchLabel={searchLabel}
                  idField={"id"}
                  labelField={"label"}
                  nestedField={"children"}
                  data={data}
                  onChange={onChange}
                />
              </Item>
            );
          })}
        </>
      );
    };

    const mapAllEntries = <T extends any>(
      view: IPanelViewOutput,
      callback: (
        asset: string,
        metric: string,
        period: Omit<IPanelPeriod, "startLabel" | "endLabel">
      ) => T
    ) =>
      (view.selected?.assets ?? context.selected?.assets)?.map((asset) =>
        (view.selected?.metrics ?? context.selected?.metrics)?.map((metric) =>
          (
            view.selected?.periods?.map(
              (periodId) =>
                omit(["startLabel", "endLabel"], periodMap[periodId])!
            ) ?? _selectedPeriods
          ).map((period) => callback(asset, metric, period))
        )
      );

    const buildOutput = (
      view: IPanelViewOutput,
      i: number
    ): ReactElement<
      TPanelFormChildrenProps<TPanelData>,
      string | JSXElementConstructor<any>
    > => {
      const { type, id } = view;

      switch (type) {
        case VisualType.COMPARISON_SUMMARY:
          return (
            <Grid
              item
              container
              spacing={1}
              style={{ margin: 0, width: "100%" }}
            >
              <Item key={i} {...view.grid}>
                <ComparisonSummaryVisual
                  id={id}
                  key={i}
                  assets={context.selected?.assets}
                  metrics={context.selected?.metrics}
                  periods={_selectedPeriods}
                  disable={false}
                />
              </Item>
            </Grid>
          );
        case VisualType.COMPARISON_CHART:
          return (
            <Grid
              item
              container
              spacing={1}
              style={{ margin: 0, width: "100%" }}
            >
              <Item key={i} {...view.grid}>
                <ComparisonChartVisual
                  id={id}
                  assets={context.selected?.assets}
                  metrics={context.selected?.metrics}
                  periods={_selectedPeriods}
                  disable={false}
                />
              </Item>
            </Grid>
          );
        case VisualType.CHART:
          return (
            <Grid
              item
              container
              spacing={1}
              style={{ margin: 0, width: "100%" }}
            >
              {mapAllEntries(
                view,
                (asset, metric, period) =>
                  METRIC_TO_CHART_ID[metric] && (
                    <Item
                      key={`${asset}-${metric}-${period.id}`}
                      {...view.grid}
                    >
                      <DefaultChartVisual
                        id={METRIC_TO_CHART_ID[metric]}
                        metrics={[metric]}
                        assets={[asset]}
                        periods={[period]}
                      />
                    </Item>
                  )
              )}
            </Grid>
          );
        case VisualType.DEFAULT_SUMMARY:
          return (
            <Grid
              item
              container
              spacing={1}
              style={{ margin: 0, width: "100%" }}
            >
              {mapAllEntries(
                view,
                (asset, metric, period) =>
                  METRIC_TO_DEFAULT_SUMMARY_ID[metric] && (
                    <Item
                      key={`${asset}-${metric}-${period.id}`}
                      {...view.grid}
                    >
                      <DefaultSummaryVisual
                        id={METRIC_TO_DEFAULT_SUMMARY_ID[metric]}
                        assets={[asset]}
                        metrics={[metric]}
                        periods={[period]}
                      />
                    </Item>
                  )
              )}
            </Grid>
          );
        default:
          throw new Error("Output type not defined.");
      }
    };

    const inputTree = buildViewTree<"input">(inputs, (view, i) =>
      buildInput(view, i)
    );
    const outputTree = buildViewTree<"output">(outputs, (view, i) =>
      buildOutput(view, i)
    );

    return (
      <Grid classes={classes}>
        {buildViewLayout(layout, 0, [inputTree, outputTree])}
      </Grid>
    );
  }
);
