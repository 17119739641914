import { PlotParams } from "react-plotly.js";

const applyDefaultProps = (
  figure: PlotParams,
  config: Partial<Plotly.Config>
) => {
  figure.layout = {
    ...figure.layout,
    autosize: true,
  };

  config.responsive = true;

  delete figure.layout.width;
  delete figure.layout.height;

  return { figure, config };
};

export default applyDefaultProps;
