import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { RootState } from "redux/types";

// TODO: fix value field
export type TSelector = {
  status?: "enabled" | "disabled";
  value?: any;
};

export type TPanel = {
  selectors: Record<string, TSelector>;
  isSubmitting: boolean;
  errorMessage: string;
};

interface IState {
  panels: Record<string, TPanel>;
}

type TUpdateSelectorsProps = Record<
  string,
  { selectors: Record<string, Partial<TSelector>> }
>;

const initialState: IState = {
  panels: {},
};

const slice = createSlice({
  name: "basePanel",
  initialState,
  reducers: {
    setPanels: (state, action: PayloadAction<Record<string, TPanel>>) => {
      Object.assign(state.panels, action.payload);
    },
    updatePanels: (
      state,
      action: PayloadAction<Record<string, Partial<TPanel>>>
    ) => {
      for (let id in action.payload) {
        if (id in state.panels) {
          state.panels[id] = {
            ...state.panels[id],
            ...action.payload[id],
          };
        }
      }
    },
    setSelectors: (
      state,
      action: PayloadAction<Record<string, Pick<TPanel, "selectors">>>
    ) => {
      for (let panelId in action.payload) {
        if (panelId in state.panels) {
          for (let selectorId in action.payload[panelId]?.selectors) {
            state.panels[panelId].selectors[selectorId] = {
              ...state.panels[panelId].selectors[selectorId],
              ...action.payload[panelId].selectors[selectorId],
            };
          }
        }
      }
    },
    updateSelectors: (state, action: PayloadAction<TUpdateSelectorsProps>) => {
      for (let panelId in action.payload) {
        if (panelId in state.panels) {
          for (let selectorId in action.payload[panelId]?.selectors) {
            if (selectorId in state.panels[panelId].selectors) {
              state.panels[panelId].selectors[selectorId] = {
                ...state.panels[panelId].selectors[selectorId],
                ...action.payload[panelId].selectors[selectorId],
              };
            }
          }
        }
      }
    },
  },
});

export const {
  setPanels,
  updatePanels,
  setSelectors,
  updateSelectors,
} = slice.actions;

// Selectors
const selectPanels = (state: RootState) => state.basePanel.panels;

export const selectPanel = createSelector(
  [
    selectPanels,
    (state: RootState, props: { panelId: string }) => props.panelId,
  ],
  (panels, panelId): TPanel | undefined => panels[panelId]
);

export const selectPanelProp = createSelector(
  [
    selectPanel,
    (state: RootState, props: { prop: keyof TPanel }) => props.prop,
  ],
  <T extends TPanel, K extends keyof T>(
    panel: T | undefined,
    prop: K
  ): T[K] | undefined => panel && panel[prop]
);

export const selectSelector = createSelector(
  [
    selectPanels,
    (state: RootState, props: { panelId: string }) => props.panelId,
    (state: RootState, props: { selectorId: string }) => props.selectorId,
  ],
  (panels, panelId, selectorId): TSelector | undefined =>
    panels[panelId]?.selectors[selectorId]
);

export const selectSelectorProp = createSelector(
  [
    selectSelector,
    (state: RootState, props: { prop: keyof TSelector }) => props.prop,
  ],
  <T extends TSelector, K extends keyof T>(
    selector: T | undefined,
    prop: K
  ): T[K] | undefined => selector && selector[prop]
);

export default slice.reducer;
