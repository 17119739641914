import { AttemptContext, AttemptOptions } from "@lifeomic/attempt";
import {
  AccessDeniedError,
  EmailOrPasswordInvalidError,
  GenericError,
  InternalServerError,
  InternetConnectionError,
  NetworkError,
} from "utils/errors";
import { FriendlyErrorInstructions } from "utils/errors/messages";

function amplifyAuthAttemptableErrorHandler(error: any) {
  if (error.code) {
    return (
      error.code === "InternalFailure" ||
      error.code === "InternalErrorException" ||
      error.code === "ServiceUnavailable" ||
      error.code === "NetworkError"
    );
  }
  // Ok, but the user is not authenticated
  return error !== "No current user";
}

function amplifyAuthErrorHandler(error: any): Error {
  const errorFrom = "CognitoError";
  const buildMessage = (errorCode: string) =>
    `Exception origin: ${errorCode} (${errorFrom})`;
  if (error && error.code) {
    switch (error.code) {
      case "NotAuthorizedException":
      case "UserNotFoundException":
      case "InvalidPasswordException":
        throw new EmailOrPasswordInvalidError(
          buildMessage(error.code),
          FriendlyErrorInstructions.notAuthorized
        );
      case "AccessDeniedException":
        throw new AccessDeniedError(
          buildMessage(error.code),
          FriendlyErrorInstructions.accessDenied
        );
      case "InternalFailure":
      case "InternalErrorException":
        throw new InternalServerError(
          buildMessage(error.code),
          FriendlyErrorInstructions.internalServer
        );
      case "NetworkError":
        if (navigator.onLine) {
          throw new NetworkError(error.code, FriendlyErrorInstructions.network);
        } else {
          throw new InternetConnectionError(
            error.code,
            FriendlyErrorInstructions.internetConnection
          );
        }
      default:
        throw new GenericError({
          message: `${error.code}: ${error.message}`,
          instructions: FriendlyErrorInstructions.generic,
        });
    }
  } else {
    throw error;
  }
}

const retryOptions: AttemptOptions<object> = {
  delay: 1000,
  maxAttempts: 5,
  minDelay: 250,
  factor: 2,
  jitter: true,
  handleError(error: any, context: AttemptContext) {
    if (error && !amplifyAuthAttemptableErrorHandler(error)) {
      context.abort();
    }
  },
  initialDelay: 0,
  maxDelay: 1000,
  timeout: 0,
  handleTimeout: null,
  beforeAttempt: null,
  calculateDelay: null,
};

export const amplifyErrorHandler = {
  authErrorHandler: amplifyAuthErrorHandler,
  retryOptions,
};
