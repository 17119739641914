import formatText from "./formatText";
import getCanvasContext from "./getCanvasContext";

const measureText = (
  text: string[],
  textTemplate: string,
  skip: number,
  fontSize: number,
  fontFamily?: string,
  measurements?: number[]
): { total: number; measurements: number[] } => {
  let total = 0;
  const buffer: number[] = [];

  for (let idx = text.length - 1; idx >= 0; idx = idx - skip) {
    const measurement: number = measurements
      ? measurements[idx]
      : Math.ceil(
          getCanvasContext(fontSize, fontFamily).measureText(
            formatText(text[idx], textTemplate)
          ).width
        );
    buffer.push(measurement);
    total += measurement;
  }

  return {
    total,
    measurements: buffer,
  };
};

export default measureText;
