import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: "auto",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  cardHeader: {
    padding: `0 0 ${theme.spacing(2)}px`,
  },
  cardContent: {
    padding: 0,
  },
  cardActions: {
    padding: `${theme.spacing(2)}px 0 0`,
  },
  codeWrapper: {
    padding: `${theme.spacing(2)}px 0`,
    "&:last-child": {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
  },
  code: {
    display: "block",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    lineHeight: theme.typography.subtitle1.lineHeight,
    fontSize: theme.typography.body2.fontSize,
  },
  copyIcon: {
    position: "absolute",
    top: 4,
    right: 4,
    fontSize: 16,
    cursor: "pointer",
  },
  info: {
    marginTop: theme.spacing(2),
  },
}));
