import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    nestedItem: {
      paddingLeft: theme.spacing(4),
    },
    menuButton: {
      padding: theme.spacing(1),
      margin: 0,
      borderRadius: 0,
    },
    body1: {
      color: theme.palette.text.secondary,
    },
    overline: {
      color: theme.palette.text.primary,
      cursor: "default",
    },
    paper: {
      minWidth: theme.spacing(28),
    },
    list: {
      "& .Mui-selected": {
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "space-between",
        "& .MuiTypography-body1": {
          color: `${theme.palette.common.white} !important`,
        },
        "& .check-icon": {
          display: "block",
          marginLeft: theme.spacing(2),
          "& svg": {
            verticalAlign: "bottom",
          },
        },
      },
      "& .MuiButtonBase-root:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
      },
      "& .MuiButtonBase-root": {
        "& .check-icon": {
          display: "none",
        },
      },
    },
  });
