import { default as AmplifyAuth } from "@aws-amplify/auth";
import { ConsoleLogger as Logger } from "utils/logger";
import { StorageType } from "./types";
import { Cache } from "aws-amplify";
import { retry } from "@lifeomic/attempt";
import { amplifyErrorHandler } from "./utils";
const { authErrorHandler, retryOptions } = amplifyErrorHandler;

const logger = new Logger("Auth");

export default class Auth {
  private static _instance: Auth;
  private _authenticated: boolean = false;

  static getInstance(): Auth {
    if (!Auth._instance) {
      Auth._instance = new Auth();
    }
    return Auth._instance;
  }

  async init(): Promise<any> {
    let session;
    try {
      this.setStorageType("session");
      session = await retry(async () => {
        session = await AmplifyAuth.currentSession();
        this._authenticated = true;
        logger.info("User is already authenticated (Session storage)");
        return session;
      }, retryOptions);
    } catch (error) {
      try {
        this.setStorageType("local");
        session = await retry(async () => {
          session = await AmplifyAuth.currentSession();
          this._authenticated = true;
          logger.info("User is already authenticated (Local storage)");
          return session;
        }, retryOptions);
      } catch (error) {
        // Ok, but the user is not authenticated
        if (error === "No current user") {
          return true;
        } else {
          authErrorHandler(error);
        }
      }
    }
    return session;
  }

  async signIn(email: string, password: string): Promise<any> {
    logger.info("Signing user in...");

    try {
      return await retry(async () => {
        const user = await AmplifyAuth.signIn(email, password);
        this._authenticated = true;
        return user;
      }, retryOptions);
    } catch (error) {
      authErrorHandler(error);
    }
  }

  async signOut(): Promise<any> {
    logger.info("Signing user out...");
    try {
      const data = await AmplifyAuth.signOut();
      logger.info("User signed out");
      return data;
    } catch (error) {
      authErrorHandler(error);
    } finally {
      this._authenticated = false;
    }
  }

  public setStorageType(storageType: StorageType) {
    const keyPrefix = "IAW2";
    let storage;
    if (storageType === "local") {
      logger.info("Setting cache to local storage...");
      storage = Cache.createInstance({
        keyPrefix,
        storage: window.localStorage,
      });
    } else {
      logger.info("Setting cache to session storage...");
      storage = Cache.createInstance({
        keyPrefix,
        storage: window.sessionStorage,
      });
    }
    AmplifyAuth.configure({ storage });
  }

  public isAuthenticated(): boolean {
    return this._authenticated;
  }
}
