import { makeStyles, createStyles } from "@material-ui/core";

export const useStylesTypography = makeStyles(() =>
  createStyles({
    body1: {
      fontStyle: "normal",
      fontSize: 22,
      fontWeight: 600,
    },
  })
);
