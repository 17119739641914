import { ThemeOptions, PaletteColorOptions, colors } from "@material-ui/core";

export const paletteDark: ThemeOptions["palette"] &
  Partial<PaletteColorOptions> = {
  type: "dark",
  primary: {
    light: "#93E2E3",
    main: "#56B2B9",
    dark: "#0A2533",
  },
  secondary: {
    main: "#0C2C3C",
    dark: "#081F2B",
  },
  error: {
    main: colors.red.A400,
  },
  background: {
    default: "#071922",
    paper: "#0D2F40",
  },
  text: {
    primary: "rgba(255, 255, 255, 0.87)",
    secondary: "rgba(255, 255, 255, 0.6)",
    disabled: "rgba(255, 255, 255, 0.38)",
  },
  divider: "#0B2837",
};
