import { useRef, useEffect, useState, CSSProperties, ReactNode } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import { useIsDesktop } from "utils/hooks";
import { useStyles } from "./styles";
import Drawer from "@material-ui/core/Drawer";
import { InputVariant } from "./InputVariant";
import getTextSize from "utils/getTextSize";
import theme from "views/Theme";
import DrawerTitleView from "views/DrawerTitleView";

export interface ISelectPlaceViewProps {
  elementId?: string;
  label?: string;
  title: string;
  value: string;
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  size?: "md" | "lg";
  variant?: "standard" | "filled";
  customInputRootCSSClass?: string;
  children: ReactNode;
}

export const SelectPlaceView = ({
  elementId,
  label,
  title,
  value,
  open,
  handleOpen,
  handleClose,
  size = "md",
  variant = "standard",
  customInputRootCSSClass,
  children,
}: ISelectPlaceViewProps) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const isDesktop = useIsDesktop();
  const [inputWidth, setInputWidth] = useState<string>("auto");

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // TODO: remove this code. Seems to be unnecessary
  useEffect(() => {
    function formatInputWidth(classNames: string[]) {
      // Padding right to avoid unnecessary ellipsis
      const paddingRight: number = theme.spacing(1);
      const style: CSSProperties = {
        position: "absolute",
        visibility: "hidden",
      };
      return `${getTextSize(value, classNames, style).width + paddingRight}px`;
    }
    setInputWidth(() => {
      if (size === "lg") {
        return formatInputWidth([classes.inputInputLg]);
      } else {
        return formatInputWidth([classes.inputInputMd]);
      }
    });
  }, [classes.inputInputLg, classes.inputInputMd, size, value]);

  return (
    <>
      <FormControl
        focused={open}
        classes={{ root: classes.formControlRoot }}
        hiddenLabel={!label}
        variant={variant}
        fullWidth
      >
        {label && <InputLabel htmlFor={elementId}>{label}</InputLabel>}
        <InputVariant
          id={elementId}
          inputRef={inputRef}
          type="text"
          value={value}
          onClick={handleOpen}
          disabled
          endAdornment={
            <InputAdornment position="end">
              {open ? (
                <ExpandLessIcon onClick={handleClick} color="action" />
              ) : (
                <ExpandMoreIcon onClick={handleClick} color="action" />
              )}
            </InputAdornment>
          }
          classes={{
            root: customInputRootCSSClass
              ? customInputRootCSSClass
              : classes.inputRoot,
            input:
              size === "lg" && isDesktop
                ? classes.inputInputLg
                : classes.inputInputMd,
          }}
          inputProps={{
            style: { width: isDesktop ? inputWidth : "100%" },
          }}
          variant={variant}
        />
      </FormControl>
      {isDesktop ? (
        <Popover
          open={open}
          anchorEl={inputRef.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          classes={{ paper: classes.popoverPaper }}
        >
          <Box mx={2} my={1}>
            {children}
          </Box>
        </Popover>
      ) : (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.drawerPaper }}
        >
          <DrawerTitleView title={title} handleClose={handleClose} />
          <Box mx={2}>{children}</Box>
        </Drawer>
      )}
    </>
  );
};
