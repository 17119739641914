import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { retry } from "@lifeomic/attempt";
import {
  retryOptions,
  parseError,
  isMutation,
  AmplifyGraphQLError,
} from "./utils";
import axios from "axios";
import { InternetConnectionError, NetworkError } from "utils/errors";
import { FriendlyErrorInstructions } from "utils/errors/messages";

// https://github.com/axios/axios/blob/7821ed20892f478ca6aea929559bd02ffcc8b063/lib/adapters/xhr.js#L99
// String returned by Axios library: Network Error
export const NETWORK_ERROR_DEFAULT_MESSAGE = "Network Error";

// See: https://github.com/aws-amplify/amplify-js/issues/6369
export async function fetchData<T extends object, I extends object = {}>(
  operation: string,
  variables?: I
): Promise<GraphQLResult<T>> {
  try {
    return await retry(
      async () => {
        return (await API.graphql(
          graphqlOperation(operation, variables)
        )) as Promise<GraphQLResult<T>>;
      },
      // Retry only if is not a mutation (idempotent request).
      {
        ...retryOptions,
        maxAttempts: isMutation(operation) ? 1 : retryOptions.maxAttempts,
      }
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message = error.message;
      if (message === NETWORK_ERROR_DEFAULT_MESSAGE) {
        if (navigator.onLine) {
          throw new NetworkError(message, FriendlyErrorInstructions.network);
        } else {
          throw new InternetConnectionError(
            message,
            FriendlyErrorInstructions.internetConnection
          );
        }
      } else {
        throw error;
      }
    } else {
      throw parseError(error as AmplifyGraphQLError);
    }
  }
}
