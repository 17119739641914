import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import { BaseChartVisual } from "features/BaseChartVisual";
import { BaseVisual } from "features/BaseVisual";
import { memo } from "react";
import { IDefaultVisualInput } from "services/api";

const styles = (theme: Theme) =>
  createStyles({
    visualBaseRootNormal: {
      "& .MuiCardHeader-content": {
        paddingRight: theme.spacing(4),
      },
    },
    visualBaseContentDesktop: {
      display: "flex",
      justifyContent: "center",
      minWidth: "220px",
      width: "100%",
      padding: 0,
      paddingBottom: "0px !important",
      height: "300px",
    },
  });

export const DefaultChartVisual = withStyles(styles)(
  memo(
    ({
      id,
      assets,
      periods,
      classes,
    }: Partial<IDefaultVisualInput> & WithStyles<typeof styles>) => {
      return (
        <BaseVisual
          input={{ id, assets, periods }}
          classes={classes}
          enableDialog
        >
          <BaseChartVisual />
        </BaseVisual>
      );
    }
  )
);
