import { createContext } from "react";

export type FeatureFlagContextType = {
  enabledFeatures: string[];
};

const FeatureFlagContext = createContext<FeatureFlagContextType>({
  enabledFeatures: [],
});

export default FeatureFlagContext;
