import { PlotParams } from "react-plotly.js";
import getScatterWithText from "./getScatterWithText";

export const formatFigure = (figure: PlotParams, skip: number) => {
  // This seems to be necessary to force Plotly to re-render despite the fact
  // that documentation says that bumping the revision attribute would be enough
  figure = JSON.parse(JSON.stringify(figure));

  const trace = getScatterWithText(figure);
  if (trace === undefined) {
    return figure;
  }

  let x: typeof trace.x = [];
  let y: typeof trace.y = [];
  let text: string[] = [];

  for (let idx = trace.meta.text.length - 1; idx >= 0; idx = idx - skip) {
    x.push(trace.meta.x[idx]);
    y.push(trace.meta.y[idx]);
    text.push(trace.meta.text[idx]);
  }

  trace.x = x;
  trace.y = y;
  trace.text = text;

  return figure;
};

export default formatFigure;
