import { PlotParams } from "react-plotly.js";
import { ConsoleLogger } from "utils/logger";
import getScatterWithText from "./getScatterWithText";
import filterTraceMode from "./filterTraceMode";
import getCanvasContext from "./getCanvasContext";
import measureText from "./measureText";

const defaultLogger = new ConsoleLogger("VisualChartViewUtils", "INFO");

const initFigure = (
  figure: PlotParams,
  logger: ConsoleLogger = defaultLogger
) => {
  const trace = getScatterWithText(figure);

  if (
    (trace && trace.meta === undefined) ||
    (trace && trace.meta && !trace.meta.initialized)
  ) {
    logger.debug("Initializing");

    // Add a new trace with markers and text
    const newTrace = JSON.parse(JSON.stringify(trace));
    newTrace.mode = filterTraceMode(
      trace.mode,
      (mode: string) => mode === "text" || mode === "markers"
    );

    // Do not show hover text for new trace
    newTrace.hoverinfo = "skip";
    delete newTrace.hoverlabel;
    delete newTrace.hovertemplate;

    // Pre-calculate text widths
    const fontSize =
      newTrace.textfont && newTrace.textfont.size ? newTrace.textfont.size : 10;
    const context = getCanvasContext(fontSize);
    const { measurements } = measureText(
      context,
      newTrace.text,
      newTrace.texttemplate,
      1
    );

    newTrace.meta = {
      ...newTrace.meta,
      // Mark trace as initialized to prevent multiple initializations
      initialized: true,
      x: [...newTrace.x],
      y: [...newTrace.y],
      text: [...newTrace.text],
      measurements,
    };

    // Add new trace to figure
    figure.data.push(newTrace);

    // Disable text and markers (if enabled) and remove unnecessary attributes
    // from original trace
    trace.mode = filterTraceMode(
      trace.mode,
      (mode: string) => mode === "lines"
    );
    trace.showlegend = false;
    delete trace.text;
    delete trace.texttemplate;
  }

  return figure;
};

export default initFigure;
