import { ThemeOptions } from "@material-ui/core";

export const breakpoints: ThemeOptions["breakpoints"] = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1600, // 1280
    xl: 1920,
  },
};
