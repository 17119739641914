import Box from "@material-ui/core/Box";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import { map, pipe, unnest } from "ramda";
import { ChangeEvent, Fragment, ReactNode, useRef, useState } from "react";
import { getNodeById, getParents } from "utils/tree";
import SearchView from "views/SearchView";
import { ISearchViewProps } from "views/SearchView/types";
import { SelectPlaceView } from "./SelectPlaceView";
import { useStyles } from "./styles";

export type DataType = {
  id: string;
  children?: DataType[];
  [key: string]: any;
};

const ContentContainer = ({ children, ...other }: ISearchViewProps) => {
  return other.label ? (
    <SearchView {...other}>{children}</SearchView>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

const formatNodeCaption = (node: DataType) => {
  let caption = node.startLabel;
  if (node.startLabel !== node.endLabel) {
    caption += `${" ─ " + node.endLabel}`;
  }
  return caption;
};

export interface ISelectTreeProps {
  elementId?: string;
  label?: string;
  title: string;
  searchLabel?: string;
  data: DataType[];
  dataFlatted: DataType[];
  dataIdField: string;
  dataLabelField: string;
  value: DataType;
  onChange: (value: DataType) => void;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  size?: "md" | "lg";
  variant?: "standard" | "filled";
  customInputRootCSSClass?: string;
  extraItem?: {
    id: string;
    label: string;
    node: ReactNode;
  };
}

export const SelectTreeView = ({
  elementId,
  label,
  title,
  searchLabel = "",
  data,
  dataFlatted,
  dataIdField,
  dataLabelField,
  value,
  onChange,
  open: openProp,
  onOpen,
  onClose,
  size = "md",
  variant = "standard",
  customInputRootCSSClass,
  extraItem,
}: ISelectTreeProps) => {
  const defaultValueId = data[0][dataIdField];
  const classes = useStyles();

  const { current: isOpenControlled } = useRef(openProp != null);
  const [openState, setOpenState] = useState<boolean>(false);

  const [selected, setSelected] = useState<string[]>([defaultValueId]);
  const [expanded, setExpanded] = useState<string[]>([defaultValueId]);

  const updateOpenState = (open: boolean) => {
    if (open) {
      if (onOpen) {
        onOpen();
      }
      if (!isOpenControlled) {
        setOpenState(true);
      }
    } else {
      if (onClose) {
        onClose();
      }
      if (!isOpenControlled) {
        setOpenState(false);
      }
    }
  };

  const handleChangeSearch = (
    event: ChangeEvent<{}>,
    value: DataType | null
  ) => {
    if (value) {
      onChange && onChange(value);
      handleClose();
    }
  };

  const handleOpen = () => {
    // When opening Popover, get the last value to share (expanded and selected)
    // state of the nodes between any instances of the component.
    const parents = pipe(
      map((d: DataType) => getParents(d, value.id)),
      unnest
    )(data);
    setExpanded(parents.length > 0 ? parents : [data[0][dataIdField]]);
    setSelected([value.id]);
    updateOpenState(true);
  };

  const handleClose = () => {
    updateOpenState(false);
  };

  const handleNodeToggle = (event: ChangeEvent<any>, nodeIds: string[]) => {
    // This event is only triggered when has children. It comes before the handleNodeSelect
    event.persist();
    let iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  const handleNodeSelect = (
    event: ChangeEvent<any>,
    nodeIds: string[] | string
  ) => {
    event.persist();
    let iconClicked = event.target.closest(".MuiTreeItem-iconContainer");
    if (!iconClicked) {
      const nodeId = Array.isArray(nodeIds) ? nodeIds[0] : nodeIds;
      const value = getNodeById(data, nodeId);
      if (value) {
        onChange(value);
        handleClose();
      }
    }
  };

  const renderTree = (node: DataType) => {
    return (
      <TreeItem
        key={node[dataIdField]}
        nodeId={node[dataIdField]}
        label={node[dataLabelField]}
        classes={{
          root: classes.treeItemRoot,
          label: classes.treeItemLabel,
          iconContainer: classes.treeItemIconContainer,
          group: classes.treeItemGroup,
          selected: classes.treeItemSelected,
        }}
      >
        {node.children?.map(renderTree) ?? null}
      </TreeItem>
    );
  };

  const EndIcon =
    data.length > 1 ? null : <div className={classes.treeEndIcon}>•</div>;

  return (
    <SelectPlaceView
      elementId={elementId}
      label={label}
      title={title}
      value={value.label}
      open={isOpenControlled && openProp !== undefined ? openProp : openState}
      handleOpen={handleOpen}
      handleClose={handleClose}
      size={size}
      variant={variant}
      customInputRootCSSClass={customInputRootCSSClass}
    >
      <ContentContainer
        label={searchLabel}
        value={null}
        onChange={handleChangeSearch}
        options={dataFlatted}
        optionLabel="label"
        optionId="id"
      >
        <Box mt={1} mb={2}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultEndIcon={EndIcon}
            onNodeSelect={handleNodeSelect}
            onNodeToggle={handleNodeToggle}
            expanded={expanded}
            selected={selected}
          >
            {data.map(renderTree)}
          </TreeView>
        </Box>
      </ContentContainer>
    </SelectPlaceView>
  );
};
