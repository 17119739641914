import { CSSProperties } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { desktopBreakpoint } from "utils/hooks/useIsDesktop";

const truncateStyled: CSSProperties = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverPaper: {
      maxHeight: `calc(100vh - ${theme.spacing(22)}px)`,
    },
    formControlRoot: {
      [theme.breakpoints.up(desktopBreakpoint)]: {
        padding: 0,
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: theme.palette.divider,
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "transparent",
        borderRadius: 0,
        "&:hover": {
          backgroundColor: "#0D3244",
        },
        "&::before": {
          borderBottom: "none",
        },
        "& > .MuiFilledInput-inputMarginDense": {
          paddingTop: 15,
          paddingBottom: 15,
        },
      },
    },
    inputRoot: {
      cursor: "pointer",
      "&.Mui-disabled": {
        cursor: "pointer",
      },
      "&.MuiInput-underline:hover:before": {
        borderBottom: `2px solid ${theme.palette.text.primary}`,
      },
      "&.MuiInput-underline.Mui-disabled:before": {
        borderBottomStyle: "solid",
      },
    },
    inputInputMd: {
      color: theme.palette.text.primary,
      cursor: "pointer",
      fontStyle: "normal",
      fontSize: 22,
      fontWeight: 600,
      ...truncateStyled,
    },
    inputInputLg: {
      color: theme.palette.text.primary,
      cursor: "pointer",
      fontStyle: "normal",
      fontSize: 46,
      fontWeight: 800,
      lineHeight: "62.74px",
      ...truncateStyled,
    },
    drawerPaper: {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    treeItemRoot: {
      position: "relative",
      marginTop: theme.spacing(1 / 2),
      marginBottom: theme.spacing(1 / 2),
      "&:focus > .MuiTreeItem-content .MuiTreeItem-label": {
        backgroundColor: theme.palette.action.focus,
        color: theme.palette.text.primary,
      },
    },
    treeItemLabel: {
      color: theme.palette.text.secondary,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(4),
      minHeight: theme.spacing(5),
      display: "flex",
      alignItems: "center",
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      transition: theme.transitions.create(["background-color"], {
        duration: theme.transitions.duration.shortest,
      }),
    },
    treeItemIconContainer: {
      minHeight: theme.spacing(5),
      minWidth: theme.spacing(3),
      borderRadius: theme.shape.borderRadius,
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.text.primary,
      },
      transition: theme.transitions.create(["background-color"], {
        duration: theme.transitions.duration.shortest,
      }),
    },
    treeItemIconContainerDisabled: {
      display: "none",
    },
    treeItemGroup: {
      marginLeft: theme.spacing(7 / 2),
    },
    treeEndIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: theme.spacing(5),
      minWidth: theme.spacing(3),
      fontSize: theme.spacing(1),
      cursor: "default",
    },
    treeItemSelected: {
      "& > .MuiTreeItem-content .MuiTreeItem-label": {
        backgroundColor: "transparent !important",
        color: theme.palette.text.primary,
        cursor: "default",
      },
      "&:before": {
        content: `"\\e876"`,
        fontFamily: "Material Icons",
        fontSize: theme.typography.button.fontSize,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: theme.spacing(5),
        minWidth: theme.spacing(3),
        position: "absolute",
        right: 0,
      },
    },
  })
);
