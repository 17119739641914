import { useTheme, useMediaQuery } from "@material-ui/core";

export const desktopBreakpoint = "md";

const useIsDesktop = () => {
  const { breakpoints } = useTheme();
  return useMediaQuery(breakpoints.up(desktopBreakpoint));
};

export default useIsDesktop;
