import { fetchData } from "../shared";
import { IListPanelsResult } from "./types";
import { listPanels as mockedListPanels } from "./__mocks__/listPanels";

const query = /* GraphQL */ `
  query {
    listPanels {
      id
      type
      label
    }
  }
`;

export const listPanels = async () => {
  const result = await fetchData<IListPanelsResult>(query);
  return result.data?.listPanels;
};

export default process.env.REACT_APP_MOCKED ? mockedListPanels : listPanels;
