import { FC } from "react";
import FeatureFlagContext from "./FeatureFlagContext";
import { featureFlags } from "featureFlags";
import { FeatureFlagType } from "./types";
import { ConsoleLogger as Logger } from "utils/logger";

const logger = new Logger("FeatureFlagsProvider");

const FeatureFlagProvider: FC = ({ children }) => {
  const enabledFeatures = (Object.keys(
    featureFlags
  ) as Array<FeatureFlagType>).filter((key) => featureFlags[key]);

  logger.debug("Enabled feature flags:", enabledFeatures);

  return (
    <FeatureFlagContext.Provider value={{ enabledFeatures }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
