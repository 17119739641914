import { FC, Fragment } from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStylesDialog = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 24,
    },
  })
);

const Wrapper: FC<DialogProps & { withDialog?: boolean }> = ({
  children,
  withDialog,
  open,
  onClose,
}) => {
  const classes = useStylesDialog();
  return withDialog ? (
    <Dialog open={open} scroll="paper" onClose={onClose}>
      <DialogContent classes={classes}>{children}</DialogContent>
    </Dialog>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export default Wrapper;
