import { memo } from "react";
import { BaseVisual } from "features/BaseVisual";
import { IDefaultVisualInput } from "services/api";
import { VisualContent } from "./VisualContent";

export const DefaultSummaryVisual = memo(
  ({ id, assets, periods }: Partial<IDefaultVisualInput>) => {
    return (
      <BaseVisual input={{ id, assets, periods }}>
        <VisualContent />
      </BaseVisual>
    );
  }
);
