import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { BaseChartVisual } from "features/BaseChartVisual";
import { BaseVisual } from "features/BaseVisual";
import { memo } from "react";
import { IComparisonVisualInput } from "services/api";
import theme from "views/Theme";

const styles = createStyles({
  visualBaseRootNormal: {
    "& .MuiCardHeader-content": {
      paddingRight: theme.spacing(4),
    },
  },
  visualBaseContentDesktop: {
    display: "flex",
    justifyContent: "center",
    minWidth: "220px",
    width: "100%",
    padding: 0,
    paddingBottom: "0px !important",
    // TODO: decrease content height
    height: "300px",
  },
});

interface IComparisonChartVisualProps extends Partial<IComparisonVisualInput> {
  disable?: boolean;
}

export const ComparisonChartVisual = withStyles(styles)(
  memo(
    ({
      id,
      assets,
      periods,
      metrics,
      colors,
      disable = false,
      classes,
    }: IComparisonChartVisualProps & WithStyles<typeof styles>) => {
      return (
        <BaseVisual
          input={Object.assign(
            { id, assets, periods, metrics },
            colors && { colors }
          )}
          classes={classes}
          disable={disable}
          enableDialog
        >
          <BaseChartVisual />
        </BaseVisual>
      );
    }
  )
);
