import { CSSProperties } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const truncateStyled: CSSProperties = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

export const useStylesTypography = makeStyles(() =>
  createStyles({
    subtitle1: {
      fontStyle: "normal",
      fontSize: 46,
      fontWeight: 800,
      lineHeight: "62.74px",
      ...truncateStyled,
    },
    body1: {
      fontStyle: "normal",
      fontSize: 22,
      fontWeight: 600,
      ...truncateStyled,
    },
    body2: {
      fontStyle: "normal",
      fontSize: 13,
      fontWeight: 400,
      ...truncateStyled,
    },
  })
);

export const useStylesSelect = makeStyles(() =>
  createStyles({
    root: {},
  })
);

export const useStylesFormControl = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInput-underline:after": {
        borderBottomColor: theme.palette.primary.light,
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: theme.palette.divider,
      },
      "& .check-icon": {
        display: "none",
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "transparent",
        borderRadius: 0,
        "&:hover": {
          backgroundColor: "#0D3244",
        },
        "&::before": {
          borderBottom: "none",
        },
        "& > .MuiFilledInput-inputMarginDense": {
          paddingTop: 15,
          paddingBottom: 15,
        },
      },
    },
  })
);

export const useStylesMenuProps = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      "& .Mui-selected": {
        backgroundColor: "transparent",
        "& p.MuiTypography-root": {
          color: `${theme.palette.common.white} !important`,
        },
        "& .check-icon": {
          display: "block",
        },
      },
      "& .MuiButtonBase-root:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.03)",
      },
      "& .MuiButtonBase-root:last-child": {
        borderBottom: "none",
      },
      "& .MuiButtonBase-root": {
        borderBottom: "1px solid #0A2533",
        margin: "0 14px",
        padding: "18px 0",
        "& .check-icon": {
          display: "none",
        },
        "& .MuiGrid-root": {
          flexDirection: "column",
          alignItems: "start",
          "& .MuiGrid-root": {
            padding: "0 8px",
            "& .MuiTypography-subtitle1": {
              fontSize: 17,
              fontWeight: "normal",
              color: theme.palette.text.secondary,
              maxWidth: "100% !important",
              lineHeight: "inherit",
            },
            "& .MuiTypography-body1": {
              fontSize: 17,
              fontWeight: "normal",
              color: theme.palette.text.secondary,
              maxWidth: "100% !important",
            },
            "& .MuiTypography-body2": {
              color: "rgba(255, 255, 255, 0.38)",
            },
          },
        },
      },
    },
  })
);

export const useStylesSelectMobile = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      "& .button-content-side-caption": {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        "& .button-label": {
          fontSize: 17,
          color: "rgba(255, 255, 255, 0.87)",
        },
        "& .button-info": {
          fontSize: 13,
          color: "rgba(255, 255, 255, 0.38)",
          paddingLeft: 16,
        },
      },
      "& .button-content": {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "& .button-label": {
          fontSize: 14,
          color: "rgba(255, 255, 255, 0.87)",
        },
        "& .button-info": {
          fontSize: 13,
          color: "rgba(255, 255, 255, 0.38)",
        },
      },
    },
    drawerPaper: {
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    listItemSecondaryAction: {
      right: theme.spacing(2),
    },
  })
);

export const useStylesSelectMobileListItem = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      borderBottom: "solid 1px #081F2B",
    },
    gutters: {
      paddingLeft: 0,
    },
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);
