import { ThemeOptions } from "@material-ui/core";

export const overrides = (
  palette: ThemeOptions["palette"],
  breakpoints: ThemeOptions["breakpoints"]
): ThemeOptions["overrides"] => ({
  MuiCssBaseline: {
    "@global": {
      html: {
        WebkitFontSmoothing: "auto",
        transition: "background-color .8s",
      },
      "input:-webkit-autofill": {
        transition: "background-color 9999s ease-in-out 0s",
        caretColor: palette?.text?.primary,
        WebkitTextFillColor: palette?.text?.primary,
      },
      "[tabindex]": {
        outline: "none",
      },
      // desktop breakpoint
      [`@media (min-width:${breakpoints?.values?.md}px)`]: {
        "*::-webkit-scrollbar": {
          width: "14px",
        },
        "*::-webkit-scrollbar-track": {
          background: palette?.background?.default,
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundClip: "content-box",
          borderRadius: "6px",
          border: "4px solid transparent",
          backgroundColor: "rgba(86, 178, 185, 0.3)",
        },
        "*::-webkit-scrollbar-button": {
          width: 0,
          height: 0,
          display: "none",
        },
        "*::-webkit-scrollbar-corner": {
          backgroundColor: "transparent",
        },
      },
    },
  },
});
