import { FC, StrictMode } from "react";
import { store } from "redux/store";
import { Provider } from "react-redux";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import ErrorBoundary from "utils/ErrorBoundary";
import theme from "views/Theme";
import { FeatureFlagProvider } from "utils/FeatureFlag";

const Providers: FC = ({ children }) => (
  <StrictMode>
    <Provider store={store}>
      <FeatureFlagProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary>{children}</ErrorBoundary>
        </ThemeProvider>
      </FeatureFlagProvider>
    </Provider>
  </StrictMode>
);

export default Providers;
