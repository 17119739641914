import { ReactNode } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { WithStyles, withStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import useIsDesktop from "utils/hooks/useIsDesktop";
import { VisualHeader } from "./VisualHeader";

const styles = (theme: Theme) => {
  const rootStyles = (theme: Theme) => ({
    marginBottom: 0,
    marginRight: 0,
    backgroundColor: theme.palette.primary.dark,
    borderRadius: theme.spacing(1),
  });

  const contentStyles = {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    padding: 0,
    paddingBottom: "0px !important",
  };

  return {
    rootNormal: rootStyles(theme),
    rootExpanded: rootStyles(theme),
    contentDesktop: contentStyles,
    contentMobile: contentStyles,
    contentExpanded: contentStyles,
  };
};

interface IBaseVisualProps extends WithStyles<typeof styles> {
  title?: string[];
  subtitle?: string[];
  className?: string;
  expanded?: boolean;
  hideHeader?: boolean;
  disable?: boolean;
  children: ReactNode;
}

export const VisualContent = withStyles(styles)(
  ({
    children,
    classes,
    className,
    title,
    subtitle,
    expanded,
    hideHeader = false,
    disable = false,
  }: IBaseVisualProps) => {
    const isDesktop = useIsDesktop();

    return (
      <Card
        elevation={4}
        className={clsx(
          {
            [classes.rootNormal]: !expanded,
            [classes.rootExpanded]: expanded,
          },
          className
        )}
      >
        {!hideHeader && (
          <VisualHeader title={title} subtitle={subtitle} disable={disable} />
        )}
        <CardContent
          className={clsx({
            [classes.contentDesktop]: !expanded && isDesktop,
            [classes.contentMobile]: !expanded && !isDesktop,
            [classes.contentExpanded]: expanded,
          })}
        >
          {children}
        </CardContent>
      </Card>
    );
  }
);
