// https://github.com/mui-org/material-ui/blob/next/CHANGELOG.md#breaking-changes-32
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  responsiveFontSizes,
  ThemeOptions,
} from "@material-ui/core";
import { typography, paletteDark, overrides, breakpoints } from "./";

const themeOptions: ThemeOptions = {
  typography,
  breakpoints,
  palette: paletteDark,
  overrides: overrides(paletteDark, breakpoints),
};

const theme = createMuiTheme(themeOptions);

export default responsiveFontSizes(theme);
