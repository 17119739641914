import { Theme, createStyles } from "@material-ui/core";
import {
  appBarSpacing,
  toolbarPanelSpacing,
} from "views/MainContentView/styles";

export const titlePanelSpacing = 4;

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: `calc(100vh - ${theme.spacing(
        appBarSpacing + toolbarPanelSpacing + titlePanelSpacing
      )}px)`,
      overflowY: "auto",
      width: "100%",
      paddingBottom: theme.spacing(1),
    },
  });
