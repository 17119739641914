import { useForm } from "react-hook-form";
import { useStyles } from "./styles";
import { ISignInFormInputs } from "./types";
import SignInView from "./views/SignInView";
import { useAppDispatch } from "redux/hooks";
import { setIsAuthenticated, setUser } from "redux/auth";
import Auth from "services/auth";
import { errorMessageBuilder } from "utils/errors/helpers";
import { FriendlyErrorMessages } from "utils/errors/messages";

const messages = {
  field: {
    email: FriendlyErrorMessages.EMAIL_INVALID,
    password: FriendlyErrorMessages.PASSWORD_INVALID,
  },
};

const SignIn = () => {
  const defaultValues = { email: "", password: "", remember: false };
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    reset,
    formState,
    setError,
  } = useForm<ISignInFormInputs>({ defaultValues });

  const onSubmit = async (data: ISignInFormInputs) => {
    const { email, password, remember } = data;
    const auth = Auth.getInstance();

    if (remember) {
      auth.setStorageType("local");
    } else {
      auth.setStorageType("session");
    }

    try {
      const { attributes } = await auth.signIn(email, password);
      dispatch(
        setUser({
          name: attributes.name,
          image: "/images/logo-energimp.svg",
        })
      );
      dispatch(setIsAuthenticated());
      reset();
    } catch (error) {
      const message = errorMessageBuilder(error as Error);
      setError("email", {
        shouldFocus: true,
        message,
        type: "server",
      });
    }
  };

  return (
    <SignInView
      classes={classes}
      onSubmit={handleSubmit(onSubmit)}
      control={control}
      formState={formState}
      messages={messages}
    />
  );
};

export default SignIn;
