import { useContext } from "react";
import FeatureFlagContext from "./FeatureFlagContext";
import { FeatureFlagType } from "./types";

const useFeatureFlagIsEnabled = (featureName: FeatureFlagType) => {
  const { enabledFeatures } = useContext(FeatureFlagContext);
  return enabledFeatures.includes(featureName);
};

export default useFeatureFlagIsEnabled;
