import Grid from "@material-ui/core/Grid";
import { IComparisonSummaryVisualData } from "services/api";
import { VisualContentLoader } from "./VisualContentLoader";
import { MetricContent } from "./MetricContent";

interface IVisualContentProps {
  content?: IComparisonSummaryVisualData["content"];
  disable?: boolean;
}

export const VisualContent = ({
  content,
  disable = false,
}: IVisualContentProps) => {
  return (
    <Grid container direction="column">
      {content && content.metrics && !disable ? (
        content.metrics.map(({ title, value, refValue, diff }, idx) => (
          <MetricContent
            title={title}
            value={value}
            refValue={refValue}
            diff={diff}
            key={idx}
          />
        ))
      ) : (
        <VisualContentLoader disable={disable} />
      )}
    </Grid>
  );
};
