import { FC } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

const LoaderView: FC<{ open: boolean }> = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop
      data-testid="loader-view"
      className={classes.backdrop}
      open={open}
    >
      {open && <CircularProgress />}
    </Backdrop>
  );
};

export default LoaderView;
