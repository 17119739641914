import { FC } from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const CopyrightView: FC = () => {
  return (
    <Typography variant="caption" align="center">
      {"Copyright © "}
      <Link href="https://inflow-analytics.com/" color="primary">
        Inflow Analytics
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export default CopyrightView;
