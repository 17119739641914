import { CSSProperties } from "react";
import { ThemeOptions } from "@material-ui/core";

export const typography: ThemeOptions["typography"] & Partial<CSSProperties> = {
  fontFamily: ["Nunito Sans", '"Helvetica Neue"', "Arial", "sans-serif"].join(
    ","
  ),
  subtitle1: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  h2: {
    fontSize: "28px",
    fontWeight: "bolder",
  },
  h3: {
    fontSize: "18px",
    fontWeight: "bolder",
  },
  button: {
    textTransform: "none",
  },
};
