import { PlotParams } from "react-plotly.js";

const getScatterWithText = (figure: PlotParams) => {
  if (figure.data) {
    for (let idx = 0; idx < figure.data.length; idx++) {
      const trace: any = figure.data[idx];
      if (
        trace.type === "scatter" &&
        trace.mode.includes("text") &&
        trace.meta &&
        trace.meta.autofittextlabels &&
        trace.meta.autofittextlabels
      ) {
        return trace;
      }
    }
  }
};

export default getScatterWithText;
