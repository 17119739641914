import { Config } from "./types";

export const config: Config = {
  region: process.env.REACT_APP_AWS_REGION || "",
  auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID || "",
    clientId: process.env.REACT_APP_CLIENT_ID || "",
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || "",
  },
  storage: {
    bucket: process.env.REACT_APP_STORAGE_BUCKET || "",
  },
  api: {
    authType: process.env.REACT_APP_API_AUTH_TYPE || "",
    url: process.env.REACT_APP_API_ENDPOINT || "",
  },
};
