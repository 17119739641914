import { colors, ThemeOptions } from "@material-ui/core";

export const paletteLight: ThemeOptions["palette"] = {
  type: "light",
  primary: {
    light: "#93E2E3",
    main: "#56B2B9",
    dark: "#0A2533",
  },
  secondary: {
    main: "#0C2C3C",
    dark: "#081F2B",
  },
  error: {
    main: colors.red.A400,
  },
  background: {
    default: "#FFF",
    paper: "#FFF",
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.6)",
  },
  divider: "#0B2837",
};
