import { ApplicationErrorMessages } from "./messages";
import { ConsoleLogger as Logger } from "utils/logger";

const logger = new Logger("Error");

abstract class BaseError extends Error {
  /**
   * Abstract base class for errors on entire application.
   */
  public logger(extraData?: any): void {
    logger.error(this);
    if (extraData) logger.error(extraData);
  }
}

export class GenericError extends BaseError {
  /**
   * Error class to catch all other application errors.
   */
  constructor(
    public extraData?: { message?: string; instructions?: string[] },
    message?: string,
    name?: string
  ) {
    super(message || ApplicationErrorMessages.GENERIC);
    this.name = name || "GenericError";
    this.logger(extraData);
  }
}

export class InternetConnectionError extends GenericError {
  /**
   * Error class to inform the user that their device is not connected to the internet.
   */
  constructor(message?: string, public instructions?: string[]) {
    super(
      { message, instructions },
      ApplicationErrorMessages.INTERNET_CONNECTION,
      "InternetConnectionError"
    );
  }
}

export class EmailOrPasswordInvalidError extends GenericError {
  /**
   * Error class to inform the user that there are errors in their email or password.
   * Currently this error message is never returned because email or password
   * validation is being done by react-hook-form. If you change the form library,
   * this message will likely be propagated.
   */
  constructor(message?: string, public instructions?: string[]) {
    super(
      { message, instructions },
      ApplicationErrorMessages.EMAIL_OR_PASSWORD_INVALID,
      "EmailOrPasswordInvalidError"
    );
  }
}

export class EmailInvalidError extends GenericError {
  /**
   * Error class to inform the user that there are errors in their email.
   */
  constructor(message?: string, public instructions?: string[]) {
    super(
      {
        message,
        instructions,
      },
      ApplicationErrorMessages.EMAIL_INVALID,
      "EmailInvalidError"
    );
  }
}

export class PasswordInvalidError extends GenericError {
  /**
   * Error class to inform the user that there are errors in their password.
   */
  constructor(message?: string, public instructions?: string[]) {
    super(
      {
        message,
        instructions,
      },
      ApplicationErrorMessages.PASSWORD_INVALID,
      "PasswordInvalidError"
    );
  }
}

export class NotAuthorizedError extends GenericError {
  /**
   * Error class to inform the user that the email or password is incorrect.
   */
  constructor(message?: string, public instructions?: string[]) {
    super(
      {
        message,
        instructions,
      },
      ApplicationErrorMessages.NOT_AUTHORIZED,
      "NotAuthorizedError"
    );
  }
}

export class AccessDeniedError extends GenericError {
  /**
   * Error class to inform the user do not have sufficient access.
   */
  constructor(message?: string, public instructions?: string[]) {
    super(
      {
        message,
        instructions,
      },
      ApplicationErrorMessages.ACCESS_DENIED,
      "AccessDeniedError"
    );
  }
}

export class ClientError extends GenericError {
  /**
   * Error class to inform the user about some client error.
   */
  constructor(message?: string, public instructions?: string[]) {
    super(
      {
        message,
        instructions,
      },
      ApplicationErrorMessages.CLIENT,
      "ClientError"
    );
  }
}

export class InternalServerError extends GenericError {
  /**
   * Error class to inform the user that there are an internal error.
   */
  constructor(message?: string, public instructions?: string[]) {
    super(
      {
        message,
        instructions,
      },
      ApplicationErrorMessages.INTERNAL_SERVER,
      "InternalServerError"
    );
  }
}

export class InternalServerErrorAttemptable extends InternalServerError {}

export class NetworkError extends GenericError {
  /**
   * Error class to inform the user that there are an error with the network.
   */
  constructor(message?: string, public instructions?: string[]) {
    super(
      {
        message,
        instructions,
      },
      ApplicationErrorMessages.NETWORK,
      "NetworkError"
    );
  }
}

// API Errors
export class APIInternalError extends GenericError {
  /**
   * Error class to inform the user that there is an internal error in the API.
   */
  constructor(message?: string, public instructions?: string[]) {
    super(
      {
        message,
        instructions,
      },
      ApplicationErrorMessages.API_INTERNAL_ERROR,
      "APIInternalError"
    );
  }
}

// VisualErrors
export class EmptyDatasetError extends GenericError {
  constructor(message?: string, public instructions?: string[]) {
    super({ message, instructions }, "", "EmptyDatasetError");
  }
}
