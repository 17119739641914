import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    subtitle1: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      color: theme.palette.text.secondary,
    },
    body1: {
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "28px",
    },
  });
