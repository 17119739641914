import { CSSProperties, createElement } from "react";
import { renderToString } from "react-dom/server";
import clsx from "clsx";

export default function getTextSize(
  value: string,
  classNames?: string[],
  style?: CSSProperties
): {
  width: number;
  height: number;
  meta: { className?: string; cssText?: string };
} {
  const reactElement = createElement("span", {
    className: clsx(classNames),
    style,
    children: value,
  });
  const wrapper = document.createElement("div");
  wrapper.innerHTML = renderToString(reactElement);

  // Its is only possible to get the offsetWidth and offsetHeight
  // if it is added to the DOM
  document.body.appendChild(wrapper);
  const {
    offsetWidth,
    offsetHeight,
    className,
    style: { cssText },
  } = wrapper.firstChild as HTMLSpanElement;

  const width: number = offsetWidth || 0;
  const height: number = offsetHeight || 0;

  wrapper.remove();
  return {
    width,
    height,
    meta: {
      className,
      cssText,
    },
  };
}
