import { SVGProps } from "react";

export default function LogoDiamonds(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="45px"
      viewBox="0 0 63 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ddd)">
        <path
          d="M10.9909 22.1719L3 30.1628L10.9909 38.1536L18.9817 30.1628L10.9909 22.1719Z"
          fill="#2C5657"
        />
        <path
          d="M25.5589 40.3078L15.7775 50.0893L25.5589 59.8708L35.3404 50.0893L25.5589 40.3078Z"
          fill="#2C5657"
        />
        <path
          d="M50.5955 26.1608L41.7761 34.9801L50.5955 43.7995L59.4148 34.9801L50.5955 26.1608Z"
          fill="#2C5657"
        />
        <path
          d="M33.9168 2.00002L19.3516 16.5653L33.9168 31.1306L48.4821 16.5653L33.9168 2.00002Z"
          fill="#56B2B9"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddd"
          x="-1"
          y="0"
          width="63.4148"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow"
            result="effect3_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
