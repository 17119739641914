import { FC } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { useStyles } from "./styles";

const ExpandIconView: FC<{ expanded: boolean }> = ({ expanded = false }) => {
  const classes = useStyles();

  return (
    <ExpandMoreIcon
      fontSize="small"
      className={clsx(classes.expand, {
        [classes.expandOpen]: expanded,
      })}
    />
  );
};

export default ExpandIconView;
