import {
  createStyles,
  fade,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { unnest } from "ramda";
import { ReactNode } from "react";
import { treeToArray } from "utils/tree";
import { PartialKey } from "utils/types";
import { ISelectTreeProps, SelectTreeView } from "views/SelectTreeView";
import { DataType } from "views/SelectTreeView/SelectTreeView";

type TSelectItem = DataType;
type TSelectData = TSelectItem[];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      "&.Mui-disabled": {
        cursor: "pointer",
      },
      "&.MuiInput-underline:hover:before": {
        borderBottom: `2px solid ${theme.palette.getContrastText(
          theme.palette.primary.dark
        )} !important`,
      },
      "&.MuiInput-underline.Mui-disabled:before": {
        borderBottom: `1px solid ${fade(theme.palette.text.disabled, 0.1)}`,
      },
    },
  });

export type SelectProps = {
  id: string;
  data: TSelectData;
  idField: string;
  status?: "enabled" | "disabled";
  labelField: string;
  nestedField?: string;
  children?: ReactNode;
} & Omit<
  ISelectTreeProps,
  | "elementId"
  | "customInputRootCSSClass"
  | "data"
  | "dataFlatted"
  | "dataIdField"
  | "dataLabelField"
> &
  PartialKey<WithStyles<typeof styles>, "classes">;

export const Select = withStyles(styles)(
  ({
    id,
    label,
    title,
    status = "enabled",
    value,
    onChange,
    open,
    onOpen,
    onClose,
    searchLabel,
    idField,
    labelField,
    nestedField = "children",
    size,
    variant,
    data,
    extraItem,
    classes,
  }: SelectProps) => {
    const dataFlatted = unnest(
      data.map((d) => treeToArray(d, [idField, labelField], nestedField))
    );

    return (
      <>
        {status === "enabled" && (
          <Box ml={1} mt={1} mr={1} mb={1}>
            <SelectTreeView
              elementId={id}
              label={label}
              title={title}
              searchLabel={searchLabel}
              data={data}
              dataFlatted={dataFlatted}
              dataIdField={idField}
              dataLabelField={labelField}
              value={value}
              onChange={onChange}
              open={open}
              onOpen={onOpen}
              onClose={onClose}
              size={size}
              variant={variant}
              customInputRootCSSClass={classes?.root}
              extraItem={extraItem}
            />
          </Box>
        )}
      </>
    );
  }
);
