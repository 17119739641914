import { ReactNode } from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import theme from "views/Theme";
import { styles } from "./styles";
interface IButtonProps extends WithStyles<typeof styles> {
  isSubmitting?: boolean;
  children: ReactNode;
}

const Button = withStyles(styles)(
  ({
    classes,
    isSubmitting,
    children,
    ...other
  }: IButtonProps & ButtonProps) => (
    <MuiButton classes={classes} {...other}>
      {isSubmitting ? (
        <CircularProgress
          color="inherit"
          size={theme.spacing(3)}
          role="spinner"
        />
      ) : (
        children
      )}
    </MuiButton>
  )
);

export default Button;
