import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Skeleton from "@material-ui/lab/Skeleton";
import { withStyles, WithStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) => ({
  title: {
    maxWidth: "430px",
    height: "25px",
  },
  subtitle: {
    maxWidth: "256px",
    height: "16px",
  },
});

interface IVisualHeaderProps extends WithStyles<typeof styles> {
  title?: string[];
  subtitle?: string[];
  disable?: boolean;
}

export const VisualHeader = withStyles(styles)(
  ({ title, subtitle, disable = false, classes }: IVisualHeaderProps) => {
    return !disable && title && subtitle ? (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        {title.map((item, idx) => (
          <Box
            key={idx}
            paddingTop={2}
            paddingBottom={2}
            paddingLeft={idx === 0 ? 2 : 1}
            paddingRight={idx === 0 ? 1 : 2}
            textAlign={idx === 0 ? "left" : "right"}
          >
            <CardHeader
              title={item}
              subheader={subtitle[idx]}
              titleTypographyProps={{ variant: "h3" }}
              subheaderTypographyProps={{ variant: "subtitle2" }}
              style={{ padding: 0 }}
            />
          </Box>
        ))}
      </Grid>
    ) : (
      <CardHeader
        title={
          <Skeleton
            variant="text"
            animation={!disable && "wave"}
            className={classes.title}
          />
        }
        subheader={
          <Skeleton
            variant="text"
            animation={!disable && "wave"}
            className={classes.subtitle}
          />
        }
      />
    );
  }
);
