import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { styles } from "./styles";
import { withStyles, WithStyles } from "@material-ui/core";
import { MetricTitle } from "./MetricTitle";
import theme from "views/Theme";
import { IComparisonSummaryVisualMetricData } from "services/api";
import WarningIcon from "@material-ui/icons/Warning";

const MISSING_VALUE_MSG = `Informação indisponível! Os dados necessários para
o cálculo deste campo não estão disponíveis.`;

const getDiffColor = (diff: string) => {
  if (diff.startsWith("+")) {
    return theme.palette.primary.light;
  } else if (diff.startsWith("-")) {
    return theme.palette.error.light;
  } else {
    return theme.palette.text.secondary;
  }
};

export const MetricContent = withStyles(styles)(
  ({
    title,
    value,
    refValue,
    diff,
    classes,
  }: IComparisonSummaryVisualMetricData & WithStyles<typeof styles>) => {
    return (
      <>
        <MetricTitle title={title} />
        <Grid
          container
          direction="row"
          style={{ padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px` }}
        >
          <Grid item container xs={6} alignItems="center">
            <Grid item>
              <Typography
                variant="body1"
                classes={classes}
                style={{ fontWeight: 600 }}
              >
                {value ? (
                  value
                ) : (
                  <WarningIcon
                    titleAccess={MISSING_VALUE_MSG}
                    style={{ verticalAlign: "middle" }}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                classes={classes}
                style={{
                  color: getDiffColor(diff),
                  marginLeft: theme.spacing(1),
                }}
              >
                {diff ? (
                  diff
                ) : (
                  <WarningIcon
                    titleAccess={MISSING_VALUE_MSG}
                    fontSize="small"
                    style={{ verticalAlign: "middle" }}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              align="right"
              classes={classes}
              style={{ fontWeight: 600 }}
            >
              {refValue ? (
                refValue
              ) : (
                <WarningIcon
                  titleAccess={MISSING_VALUE_MSG}
                  style={{ verticalAlign: "middle" }}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
);
