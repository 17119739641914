import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/types";
import { cache } from "utils/storage";
import { getNodeById } from "utils/tree";
import { IPanelState } from "./types";
import { IAssetNode, IPanelPeriod } from "./types";

export const PERIOD_ID = "PeriodId";
export const SECONDARY_PERIOD_ID = "SecondaryPeriodId";
export const ASSET_ID = "AssetId";
export const SECONDARY_ASSET_ID = "SecondaryAssetId";
export const METRIC_ID = "MetricId";
export const SECONDARY_METRIC_ID = "SecondaryMetricId";

export const initialState: IPanelState = {
  title: undefined,
  assetId: cache.getItem(ASSET_ID),
  assetTree: undefined,
  periodId: cache.getItem(PERIOD_ID),
  periods: undefined,
};

const panelSlice = createSlice({
  name: "panel",
  initialState,
  reducers: {
    setPanelTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setPanelAssetTree: (
      state,
      action: PayloadAction<IAssetNode | undefined>
    ) => {
      if (action.payload) {
        state.assetTree = action.payload;
      }
    },
    setPanelAssetId: (state, action: PayloadAction<string | undefined>) => {
      let assetId: string | undefined;

      if (action.payload === undefined) {
        assetId = initialState.assetId;
      } else {
        assetId = action.payload;
      }

      // Checking if assetId exists in the assetTree
      let asset: IAssetNode | undefined;
      if (assetId && state.assetTree) {
        // TODO: fix type cast using generics
        asset = getNodeById(state.assetTree, assetId) as IAssetNode;
      }

      // Validating assetId
      if (asset === undefined && state.assetTree) {
        assetId = state.assetTree.id;
      }

      // Updating cache
      cache.setItem(ASSET_ID, assetId);
      state.assetId = assetId;
    },
    setPanelPeriodId: (state, action: PayloadAction<string | undefined>) => {
      let periodId: string | undefined;

      if (action.payload === undefined) {
        periodId = initialState.periodId;
      } else {
        periodId = action.payload;
      }

      // Validating periodId
      if (state.periods) {
        if (state.periods.filter((p) => periodId === p.id).length === 0) {
          periodId = state.periods[0].id;
        }
      }

      // Updating cache, querystring and state
      cache.setItem(PERIOD_ID, periodId);
      state.periodId = periodId;
    },
    setPanelPeriods: (
      state,
      action: PayloadAction<IPanelPeriod[] | undefined>
    ) => {
      if (action.payload) {
        state.periods = action.payload;
      }
    },
  },
});

export const {
  setPanelTitle,
  setPanelAssetTree,
  setPanelAssetId,
  setPanelPeriodId,
  setPanelPeriods,
} = panelSlice.actions;

export const selectPanelTitle = (state: RootState) => state.panel.title;
export const selectPanelAssetId = (state: RootState) => state.panel.assetId;
export const selectPanelAssetTree = (state: RootState) => state.panel.assetTree;
export const selectPanelPeriodId = (state: RootState) => state.panel.periodId;
export const selectPanelPeriods = (state: RootState) => state.panel.periods;

export default panelSlice.reducer;
