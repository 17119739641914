const formatText = (text: string, template: string): string => {
  const pattern = /%{text:\.([0-9]+)(.)}/;
  const match = template.match(pattern);
  if (match) {
    const decimalPlaces = Number(match[1]);
    const percent = 100 * Number(text);
    return percent.toFixed(decimalPlaces) + `${match[2]}`;
  } else {
    throw Error(`Invalid text template: ${template}`);
  }
};

export default formatText;
