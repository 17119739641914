import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import panelReducer from "./panel";
import navMenuReducer from "./nav-menu";
import basePanelReducer from "features/BasePanel/redux";
import logger from "redux-logger";

export const reducer = {
  auth: authReducer,
  panel: panelReducer,
  navMenu: navMenuReducer,
  basePanel: basePanelReducer,
};

// TODO: enable redux-logger only in development
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
