import React, { FC, forwardRef } from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ISelectViewProps } from "./types";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CheckIcon from "@material-ui/icons/Check";
import { useStylesSelectMobile, useStylesSelectMobileListItem } from "./styles";
import clsx from "clsx";
import DrawerTitleView from "views/DrawerTitleView";
import theme from "views/Theme";
import { fade } from "@material-ui/core";

const SelectMobileView: FC<ISelectViewProps> = forwardRef(
  (
    { label, optionId, options, selOption, className, style, isSideCaption },
    ref
  ) => {
    const classes = useStylesSelectMobile();
    const classesListItem = useStylesSelectMobileListItem();
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleDrawer = () => (
      event: React.KeyboardEvent | React.MouseEvent
    ) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(!isOpen);
    };

    const optionValue = options.find((item) => item.id === optionId);

    if (!options.length || !optionValue) return null;

    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={() => setIsOpen(true)}
          className={clsx(classes.button, className)}
          /**
           * It is necessary to define these styles here to make sure they
           * will take precedence over Mui-Button styles.
           */
          style={{ borderRadius: 18, textTransform: "none", ...style }}
        >
          <div
            className={
              isSideCaption ? "button-content-side-caption" : "button-content"
            }
          >
            <div className="button-label">{optionValue.label}</div>
            {optionValue.caption && (
              <div className="button-info">{optionValue.caption}</div>
            )}
          </div>
        </Button>
        <Drawer
          anchor="bottom"
          open={isOpen}
          onClose={() => setIsOpen(false)}
          classes={{ paper: classes.drawerPaper }}
          ref={ref}
        >
          <DrawerTitleView
            title={label?.toString()}
            handleClose={() => setIsOpen(false)}
          />
          <List onClick={toggleDrawer()} onKeyDown={toggleDrawer()}>
            {options &&
              options.map((item, idx) => (
                <ListItem
                  button
                  key={item.id}
                  onClick={() => selOption(item.id)}
                  divider={idx + 1 < options.length}
                  classes={classesListItem}
                >
                  <ListItemText
                    primary={
                      <div>
                        <div
                          style={{
                            fontSize: 17,
                            fontWeight: 400,
                            color:
                              optionValue.id === item.id
                                ? theme.palette.common.white
                                : theme.palette.text.secondary,
                          }}
                        >
                          {item.label}
                        </div>
                        {item.caption && (
                          <div
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              color: fade(theme.palette.text.secondary, 0.38),
                            }}
                          >
                            {item.caption}
                          </div>
                        )}
                      </div>
                    }
                  />
                  <ListItemSecondaryAction
                    classes={{ root: classes.listItemSecondaryAction }}
                  >
                    {optionValue.id === item.id && <CheckIcon />}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </Drawer>
      </>
    );
  }
);

export default SelectMobileView;
