import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/types";
import { INavMenuItemState, INavMenuState } from "./types";

export const GENERATION_PANEL_PATH = "/generation";
export const COMPARISON_PANEL_PATH = "/comparison";

export const initialState: INavMenuState = {
  isLoaded: false,
  menu: {
    signIn: { label: "Sign-in", path: "/sign-in" },
    panel: {
      label: "Painel",
      path: "/panel",
      children: [
        { label: "Geração", path: GENERATION_PANEL_PATH },
        { label: "Comparação", path: COMPARISON_PANEL_PATH },
      ],
    },
    report: {
      label: "Relatórios",
      path: "/reports",
    },
  },
};

const navMenuSlice = createSlice({
  name: "navMenu",
  initialState,
  reducers: {
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
    setReportChildren: (state, action: PayloadAction<INavMenuItemState[]>) => {
      state.menu.report.children = action.payload;
    },
  },
});

export const { setIsLoaded, setReportChildren } = navMenuSlice.actions;

export const selectIsLoaded = (state: RootState) => state.navMenu.isLoaded;
export const selectNavMenu = (state: RootState) => state.navMenu.menu;

export default navMenuSlice.reducer;
