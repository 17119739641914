import { PlotParams } from "react-plotly.js";
import applyDefaultProps from "./applyDefaultProps";

export const handleDesktopProps = (
  figure: PlotParams,
  config: Partial<Plotly.Config>
) => {
  const plot = applyDefaultProps(figure, config);
  plot.figure.layout = {
    ...plot.figure.layout,
    showlegend: true,
    margin: {
      ...plot.figure.layout.margin,
      t: 8,
      l: 56,
      r: 60,
      b: 54,
    },
  };

  return plot;
};

export default handleDesktopProps;
