import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { styles } from "./styles";
import { withStyles, WithStyles } from "@material-ui/core";
import theme from "views/Theme";

export const MetricTitle = withStyles(styles)(
  ({ title, classes }: { title: string } & WithStyles<typeof styles>) => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ padding: `0 ${theme.spacing(2)}px 0` }}
      >
        <Grid item>
          <Typography
            variant="subtitle1"
            classes={classes}
            style={{ fontSize: 11, fontWeight: 400 }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs>
          <Divider
            style={{ backgroundColor: theme.palette.background.paper }}
          />
        </Grid>
      </Grid>
    );
  }
);
