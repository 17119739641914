import { VisualType } from "services/api";
import { IPeriod } from "../types";

enum PanelType {
  GENERATION = "GENERATION",
  COMPARISON = "COMPARISON",
}

export interface IAssetNode {
  id: string;
  type: AssetNodeType;
  label: string;
  children?: IAssetNode[];
}

interface IPanelData {
  id: string;
  type: PanelType;
  label: string;
  assets: IAssetNode;
  visualList: IVisualListItem[];
}

enum AssetNodeType {
  PORTFOLIO = "PORTFOLIO",
  COMPLEX = "COMPLEX",
  WIND_FARM = "WIND_FARM",
}

export interface IPanelPeriod extends IPeriod {
  startLabel: string;
  endLabel: string;
}

interface IVisualListItem {
  id: string;
  type: VisualType;
}

export interface IGenerationPanelData extends IPanelData {
  periods: IPanelPeriod[];
}

export interface IMetricListItem {
  id: string;
  label: string;
}

export interface IComparisonPanelData extends IPanelData {
  primaryPeriods: IPanelPeriod[];
  secondaryPeriods: IPanelPeriod[][];
  metricList: IMetricListItem[];
}

export type TPanelData = IGenerationPanelData | IComparisonPanelData;

export interface IGetPanelResult {
  getPanel: TPanelData;
}

export interface IPanelListItem {
  id: string;
  type: PanelType;
  label: string;
}

export interface IListPanelsResult {
  listPanels: IPanelListItem[];
}
