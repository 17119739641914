import { FC } from "react";
import SignInFeature from "features/SignInFeature";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { VerticalLogo } from "views/LogoView";
import Copyright from "views/CopyrightView";
import { useStyles } from "./styles";

const SignInScene: FC = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid className={classes.wrapper}>
        <Container component="main" maxWidth="xs" className={classes.container}>
          <VerticalLogo className={classes.logo} role="logo" />
          <SignInFeature />
          <Box mt={4}>
            <Copyright />
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default SignInScene;
