import { fetchData } from "../shared";
import {
  IGetVisualResult,
  TVisualData,
  TVisualInput,
  IVisualInput,
  IComparisonVisualInput,
} from "./types";
import mockedFetchVisual from "./__mocks__/fetchVisual";

const instanceOfComparisonVisualInput = (
  input: TVisualInput
): input is IComparisonVisualInput => {
  return "metrics" in input;
};

const query = /* GraphQL */ `
  query GetVisual($visualInput: VisualInput!) {
    getVisual(visualInput: $visualInput) {
      id
      type
      title
      subtitle
      ... on ChartVisual {
        content {
          plotly
          config {
            displayModeBar
            displaylogo
          }
        }
      }
      ... on DefaultSummaryVisual {
        content {
          metricDescription
          metricValue
          goalDescription
          goalValue
          mainValue
        }
      }
      ... on ComparisonSummaryVisual {
        content {
          metrics {
            title
            value
            refValue
            diff
          }
        }
      }
    }
  }
`;

const getVisual = async <I extends TVisualInput, D extends TVisualData>(
  input: I
): Promise<D | undefined> => {
  const visualInput = instanceOfComparisonVisualInput(input)
    ? { visualInput: { comparisonVisualInput: input } }
    : { visualInput: { defaultVisualInput: input } };

  const result = await fetchData<IGetVisualResult>(query, visualInput);
  return result.data?.getVisual as D | undefined;
};

//export default process.env.REACT_APP_MOCKED ? mockedFetchVisual : getVisual;
export default getVisual;
