import ReactDOM from "react-dom";
import Providers from "./Providers";
// Required to use type import, as a plain side-effect import will be emitted to runtime.
import type {} from "@material-ui/lab/themeAugmentation";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
