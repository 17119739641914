import { withStyles, WithStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SkeletonTypographyView from "views/SkeletonTypographyView";

const styles = {
  body2: {
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "58px",
  },
};

interface IMainProps extends WithStyles<typeof styles> {
  info?: string;
}

export const MainField = withStyles(styles)(({ info, classes }: IMainProps) => {
  return info ? (
    <Typography classes={classes} variant="body2" align="center">
      {info}
    </Typography>
  ) : (
    <SkeletonTypographyView
      width={240}
      variant="body2"
      justify="center"
      classes={classes}
    />
  );
});
