import { withStyles, WithStyles, Theme } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useIsDesktop from "utils/hooks/useIsDesktop";
import theme from "views/Theme";

const styles = (theme: Theme) => ({
  text: {
    transform: "none",
    marginBottom: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`,
  },
});

interface ILoaderChartVisualProps extends WithStyles<typeof styles> {
  disable?: boolean;
}

const LoaderChartVisual = ({
  disable = false,
  classes,
}: ILoaderChartVisualProps) => {
  const isDesktop = useIsDesktop();
  return (
    <Skeleton
      animation={!disable && "wave"}
      variant="text"
      height={isDesktop ? 300 - theme.spacing(2) : 200 - theme.spacing(2)}
      classes={{
        text: classes.text,
      }}
    />
  );
};

export default withStyles(styles)(LoaderChartVisual);
