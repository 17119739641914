import { ChangeEvent } from "react";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import { useIsDesktop } from "utils/hooks";
import SelectMobileView from "views/SelectView/SelectMobileView";
import { ISelectViewProps } from "views/SelectView/types";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    tabRoot: {
      color: theme.palette.text.secondary,
      opacity: 1,
      minWidth: 0,
      "&:hover": {
        color: theme.palette.primary.main,
        opacity: 1,
      },
    },
    tabSelected: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    button: {
      backgroundColor: "transparent",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      fontSize: 14,
    },
  });

export const Tabs = withStyles(styles)(
  ({
    title,
    optionId,
    options,
    selOption,
    classes,
    ...other
  }: ISelectViewProps & WithStyles<typeof styles>) => {
    const isDesktop = useIsDesktop();

    const handleChange = (event: ChangeEvent<{}>, newValue: string) => {
      selOption(newValue);
    };

    if (isDesktop) {
      return (
        <MuiTabs
          value={optionId}
          onChange={handleChange}
          aria-label="toolbar-panel-tab"
          indicatorColor="primary"
        >
          {options.map((option) => (
            <MuiTab
              key={option.id}
              label={option.label}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              value={option.id}
              disableRipple
            />
          ))}
        </MuiTabs>
      );
    } else {
      return (
        <SelectMobileView
          label={title}
          optionId={optionId}
          options={options}
          selOption={selOption}
          className={classes.button}
          {...other}
        />
      );
    }
  }
);
