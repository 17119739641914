import { withStyles, WithStyles, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SkeletonTypographyView from "views/SkeletonTypographyView";

const styles = (theme: Theme) => ({
  subtitle1: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  body1: {
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "28px",
  },
});

interface IInfoProps extends WithStyles<typeof styles> {
  description?: string;
  info?: string;
}

export const InfoField = withStyles(styles)(
  ({ description, info, classes }: IInfoProps) => {
    return description && info ? (
      <>
        <Typography classes={classes} variant="subtitle1" align="center">
          {description}
        </Typography>
        <Typography classes={classes} variant="body1" align="center">
          {info}
        </Typography>
      </>
    ) : (
      <>
        <SkeletonTypographyView
          width={100}
          variant="subtitle1"
          justify="center"
          classes={classes}
        />
        <SkeletonTypographyView
          width={100}
          variant="body1"
          justify="center"
          classes={classes}
        />
      </>
    );
  }
);
