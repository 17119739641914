import { FC } from "react";
import Typography from "@material-ui/core/Typography";
import Grid, { GridJustification } from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useStylesTypography } from "./styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { IDrawerTitleViewProps } from "./types";

const DrawerTitleView: FC<IDrawerTitleViewProps> = ({ title, handleClose }) => {
  const classesTypography = useStylesTypography();

  if (!title && !handleClose) {
    return null;
  }

  function handleJustify(): GridJustification {
    if (title && !handleClose) {
      return "flex-start";
    } else if (!title && handleClose) {
      return "flex-end";
    } else {
      return "space-between";
    }
  }

  return (
    <Grid container justify={handleJustify()} alignItems="center">
      {title && (
        <Box p={2}>
          <Typography variant="body1" classes={classesTypography}>
            {title}
          </Typography>
        </Box>
      )}
      {handleClose && (
        <Box p={1}>
          <IconButton aria-label="close" onClick={() => handleClose()}>
            <CloseIcon color="disabled" />
          </IconButton>
        </Box>
      )}
    </Grid>
  );
};

export default DrawerTitleView;
