import { FC } from "react";
import Button from "views/ButtonView";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Controller } from "react-hook-form";
import { ISignInViewProps } from "../types";
import Alert from "@material-ui/lab/Alert";

const SignInView: FC<ISignInViewProps> = ({
  classes,
  onSubmit,
  control,
  formState,
  messages,
}) => {
  const { email: emailError, password: passwordError } = formState.errors;
  const isServerError = emailError?.type === "server";
  const emailMessageError = emailError?.message;
  const passwordMessageError = passwordError?.message;

  return (
    <form
      className={classes.form}
      onSubmit={onSubmit}
      data-testid="sign-in-form"
    >
      {isServerError && (
        <Alert severity="error" variant="filled">
          {emailMessageError}
        </Alert>
      )}
      <Controller
        name="email"
        control={control}
        rules={{
          required: {
            value: true,
            message: messages.field.email,
          },
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: messages.field.email,
          },
        }}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              label="Email"
              role="email"
              autoFocus
              color="primary"
              margin="normal"
              id="email"
              disabled={formState.isSubmitting}
              error={emailError && !isServerError}
              helperText={!isServerError && emailMessageError}
              inputProps={{
                "data-testid": "email-field",
              }}
              fullWidth
            />
          );
        }}
      />
      <Controller
        name="password"
        control={control}
        rules={{
          required: {
            value: true,
            message: messages.field.password,
          },
        }}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              label="Senha"
              role="password"
              type="password"
              color="primary"
              margin="normal"
              id="password"
              disabled={formState.isSubmitting}
              error={!!passwordError}
              helperText={passwordMessageError}
              inputProps={{
                "data-testid": "password-field",
              }}
              fullWidth
            />
          );
        }}
      />
      <Box pt={2}>
        <Grid container alignItems="center">
          <Controller
            name="remember"
            control={control}
            render={({ field }) => {
              return (
                <Checkbox
                  {...field}
                  color="primary"
                  id="remember"
                  role="remember"
                  disabled={formState.isSubmitting}
                  value={field.value}
                />
              );
            }}
          />
          <Box ml={1}>
            <InputLabel htmlFor="remember">
              <Typography variant="body2" color="textPrimary">
                Continuar conectado?
              </Typography>
            </InputLabel>
          </Box>
        </Grid>
      </Box>
      <br />
      <Button
        type={"submit"}
        variant={"contained"}
        color={"primary"}
        disabled={formState.isSubmitting}
        fullWidth
        role="submit"
        isSubmitting={formState.isSubmitting}
      >
        Entrar
      </Button>
    </form>
  );
};

export default SignInView;
