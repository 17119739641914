import { DataType } from "views/SelectTreeView/SelectTreeView";

type TreeDataType = DataType;

export function treeToArray(
  node: TreeDataType,
  fields: string[],
  childrenField: string
): TreeDataType[] {
  let flatData: TreeDataType[] = [];
  if (!node) {
    return [];
  }

  if (!node[childrenField]) {
    return [];
  }
  function addItemToArray(node: TreeDataType) {
    if (fields.every((curr) => Object.keys(node).includes(curr))) {
      let tempNode: any = {};
      fields.forEach((field: string) => (tempNode[field] = node[field]));
      // delete tempNode[childrenField];
      flatData.push(tempNode);
    } else {
      throw Error(
        `Some field(s) do not exist in the tree: ${JSON.stringify(fields)}`
      );
    }
    if (Array.isArray(node[childrenField]) && node[childrenField].length > 0) {
      node[childrenField].forEach((item: TreeDataType) => {
        addItemToArray(item);
      });
    }
  }
  if (node) {
    addItemToArray(node);
  }
  return flatData;
}

export function getParentId(
  node: TreeDataType,
  childId: string
): string | null {
  let parentId: string | null = null;
  function findParent(node: TreeDataType, childId: string) {
    var innerParentId = node.id;
    if (Array.isArray(node.children) && node.children.length > 0) {
      node.children.forEach((item: TreeDataType) => {
        if (item.id === childId) {
          parentId = innerParentId;
        } else {
          findParent(item, childId);
        }
      });
    }
  }
  findParent(node, childId);
  return parentId;
}

export function getParents(node: TreeDataType, childId: string): string[] {
  let parents: string[] = [];
  function addParentToArray(node: TreeDataType, childId: string) {
    let parentId = getParentId(node, childId);
    if (parentId) {
      parents.push(parentId);
      addParentToArray(node, parentId);
    }
  }
  addParentToArray(node, childId);
  return parents;
}

const findNode = (
  node: TreeDataType,
  nodeId: string
): TreeDataType | undefined => {
  if (node.id === nodeId) {
    return node;
  } else {
    if (Array.isArray(node.children) && node.children.length > 0) {
      return findInList(node.children, nodeId);
    }
  }
};

const findInList = (
  list: TreeDataType[],
  nodeId: string
): TreeDataType | undefined => {
  for (let idx = 0; idx < list.length; idx++) {
    const foundNode = findNode(list[idx], nodeId);

    if (foundNode) {
      return foundNode;
    }
  }
};

export const getNodeById = (
  node: TreeDataType | TreeDataType[],
  nodeId: string
): TreeDataType | undefined => {
  if (Array.isArray(node)) {
    return findInList(node, nodeId);
  } else {
    return findNode(node as TreeDataType, nodeId);
  }
};
