import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import { useStyles } from "./styles";
import { ISearchViewProps } from "./types";

const SearchView = ({
  label,
  value,
  onChange,
  options,
  children,
  optionLabel,
  optionId,
}: ISearchViewProps) => {
  const classes = useStyles();

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    value,
    onChange,
    options,
    getOptionLabel: (option) => (optionLabel ? option[optionLabel] : option),
    getOptionSelected: (option, value) =>
      optionId ? option[optionId] === value[optionId] : value,
    clearOnEscape: true,
  });

  return (
    <Box mb={2}>
      <FormControl
        size="medium"
        {...getRootProps()}
        classes={{ root: classes.formControlRoot }}
        fullWidth
      >
        <InputLabel {...getInputLabelProps()}>{label}</InputLabel>
        <Input
          {...getInputProps()}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon fontSize="small" color="disabled" />
            </InputAdornment>
          }
          placeholder="Tecle Esc para limpar ou voltar"
        />
      </FormControl>
      {groupedOptions.length > 0 ? (
        <List {...getListboxProps()} className={classes.listBox}>
          {groupedOptions.map((option, index) => (
            <ListItem {...getOptionProps({ option, index })}>
              <ListItemText
                primary={optionLabel ? option[optionLabel] : option}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        children || null
      )}
    </Box>
  );
};

export default SearchView;
