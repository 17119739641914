import { PlotParams } from "react-plotly.js";
import getScatterWithText from "./getScatterWithText";
import measureText from "./measureText";
import { ConsoleLogger } from "utils/logger";

const defaultLogger = new ConsoleLogger("VisualChartViewUtils", "INFO");

const searchBestSkipValue = (
  figure: PlotParams,
  plotWidth: number,
  skip: number,
  increasing: boolean,
  totalTextWidth: number | null,
  logger: ConsoleLogger = defaultLogger,
  trace: any = undefined
): number | undefined => {
  // It's impossible to optimize a plot with zero width
  if (plotWidth === 0) {
    return;
  }

  if (trace === undefined) {
    trace = getScatterWithText(figure);
    if (trace === undefined) {
      return skip;
    }
  }

  const fontSize =
    trace.textfont && trace.textfont.size ? trace.textfont.size : 10;

  if (!totalTextWidth) {
    const { total: textTotal } = measureText(
      trace.meta.text,
      trace.texttemplate,
      skip,
      fontSize,
      trace.meta.measurements
    );
    totalTextWidth = textTotal;
  }

  logger.debug(
    `Checking { plotWidth: ${plotWidth.toFixed(
      2
    )}, textWidth: ${totalTextWidth}, skip: ${skip} }`
  );

  if (increasing) {
    if (totalTextWidth > plotWidth) {
      if (skip < trace.meta.text.length - 1) {
        return searchBestSkipValue(
          figure,
          plotWidth,
          skip + 1,
          increasing,
          null,
          logger,
          trace
        );
      } else {
        return;
      }
    } else {
      return skip;
    }
  } else {
    if (totalTextWidth < plotWidth) {
      if (skip > 1) {
        const bestSkip = searchBestSkipValue(
          figure,
          plotWidth,
          skip - 1,
          increasing,
          null,
          logger,
          trace
        );
        return bestSkip ? bestSkip : skip;
      } else {
        return skip;
      }
    } else {
      return;
    }
  }
};

export default searchBestSkipValue;
