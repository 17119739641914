import { Component, ReactNode } from "react";
import ErrorView from "views/ErrorView";
import { FriendlyErrorMessages } from "./errors/messages";
import Box from "@material-ui/core/Box";

interface IErrorBoundaryProps {
  children: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
  errorExtraData: any;
}

class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  public constructor(props: IErrorBoundaryProps) {
    super(props);
  }

  public state: IErrorBoundaryState = {
    hasError: false,
    errorMessage: "",
    errorExtraData: "",
  };

  public static getDerivedStateFromError(
    error: Error & { extraData: any }
  ): IErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      errorMessage: error.message,
      errorExtraData: error.extraData,
    };
  }

  public render() {
    const { errorMessage, errorExtraData } = this.state;

    if (this.state.hasError) {
      return (
        <Box width="100%">
          <ErrorView
            title={FriendlyErrorMessages.GENERIC_TITLE}
            errorMessage={errorMessage}
            errorExtraData={errorExtraData}
            handleRetry={() => window.location.reload()}
          />
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
