import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  plotlyDiv: {
    width: "100%",
    height: "100%",
    "& .xy2": {
      clipPath: "none",
      "& .textpoint text": {
        textShadow: "1px 1px 1px rgba(0, 0, 0, .7)",
      },
    },
    "& .hovertext": {
      "& .name": {
        display: "none",
      },
      "& rect": {
        display: "none",
      },
    },
  },
});
