import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      maxWidth: theme.breakpoints.values.lg,
      "& .MuiCard-root": {
        borderRadius: 0,
      },
      "& .MuiPaper-elevation4": {
        boxShadow: "none",
      },
    },
    container: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    content: {
      width: "100%",
      height: "100%",
    },
    dialogContent: {
      padding: 0,
    },
    zoomBox: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "100%",
        top: 0,
        right: 0,
      },
    },
    zoomIconButton: {
      height: "100%",
      width: "100%",
      borderRadius: "10px",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
      },
    },
    zoomIcon: {
      fontSize: "20px",
      color: theme.palette.grey[500],
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  });
