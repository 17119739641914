import Grid, { GridJustification } from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Skeleton, { SkeletonProps } from "@material-ui/lab/Skeleton";
import { TypographyVariant } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import { withStyles, WithStyles, Theme } from "@material-ui/core";

type Variant = TypographyVariant | "srOnly";
type Classes = Partial<ClassNameMap<string>>;

const styles = (theme: Theme) => ({
  subtitle1: {},
  body1: {},
  body2: {},
});

interface ISkeletonTypographyProps {
  width: number;
  variant: Variant;
  animation?: SkeletonProps["animation"];
  justify: GridJustification;
  disable?: boolean;
  classes?: Classes;
  className?: string;
}

const SkeletonTypographyView = ({
  width,
  variant,
  animation = "wave",
  justify,
  disable = false,
  classes,
  className,
}: ISkeletonTypographyProps & WithStyles<typeof styles>) => {
  return (
    <Grid container justify={justify}>
      <Typography
        variant={variant}
        className={className}
        classes={{
          subtitle1: classes.subtitle1,
          body1: classes.body1,
          body2: classes.body2,
        }}
      >
        <Skeleton animation={animation} width={width} />
      </Typography>
    </Grid>
  );
};

export default withStyles(styles)(SkeletonTypographyView);
