import { handleDesktopProps, handleMobileProps } from "./utils";
import { IPlotlyProps } from "./types";
import useIsDesktop from "utils/hooks/useIsDesktop";
import { useStyles } from "./styles";
import PlotlyComponentHandler from "./PlotlyComponentHandler";

export const Plotly = ({ plotly, config }: IPlotlyProps) => {
  const isDesktop = useIsDesktop();
  const styles = useStyles();

  let plot;
  if (isDesktop) {
    plot = handleDesktopProps(plotly, config);
  } else {
    plot = handleMobileProps(plotly, config);
  }

  return (
    <PlotlyComponentHandler
      plotly={plot.figure}
      config={plot.config}
      className={styles.plotlyDiv}
    />
  );
};
