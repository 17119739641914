import { FC } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { IRouteProps, ILocationState } from "./types";
import { ConsoleLogger as Logger } from "utils/logger";

const logger = new Logger("UnauthRoute");

const UnauthenticatedRoute: FC<IRouteProps> = ({
  redirect,
  isAuthenticated,
  children,
  ...rest
}) => {
  const { state, pathname, search } = useLocation<ILocationState>();
  const referrer = state ? state.referrer : null || redirect;

  if (isAuthenticated) {
    logger.debug(
      `Current location: ${pathname}. User is authenticated.`,
      `Redirecting to ${referrer}`
    );
  } else {
    logger.debug(`Current location: ${pathname}. User is not authenticated`);
  }

  return (
    <Route {...rest}>
      {!isAuthenticated ? children : <Redirect to={referrer + search} />}
    </Route>
  );
};

export default UnauthenticatedRoute;
