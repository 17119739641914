import { default as AmplifyStorage } from "@aws-amplify/storage";
import HttpClient from "../http";
import { retry } from "@lifeomic/attempt";
import { retryOptions } from "./utils";
import { GenericError, NetworkError } from "utils/errors";
import { FriendlyErrorInstructions } from "utils/errors/messages";

export default class Storage extends HttpClient {
  private static instance: Storage;

  private constructor() {
    super();
  }

  public init(): Promise<boolean> {
    return Promise.resolve(true);
  }

  public async get(fileName: string, presignedUrl?: boolean): Promise<any> {
    let signedUrl: any;
    try {
      signedUrl = await retry(
        async () => {
          return await AmplifyStorage.get(fileName);
        },
        {
          ...retryOptions,
          handleError: null,
        }
      );
    } catch (error) {
      if (error === "No credentials") {
        // The application failed to send a request to
        // https://cognito-identity.us-east-1.amazonaws.com
        throw new NetworkError(
          `${error}: The application failed to send a request to cognito-identity.`,
          FriendlyErrorInstructions.network
        );
      } else {
        throw new GenericError(
          {
            message: JSON.stringify(error),
            instructions: FriendlyErrorInstructions.generic,
          },
          "At Storage.get: Error."
        );
      }
    }

    if (presignedUrl) {
      return signedUrl;
    } else {
      return await retry(async () => {
        return await this.instance.get(signedUrl);
      }, retryOptions);
    }
  }

  public async getAll(
    fileName: string,
    presignedUrl?: boolean
  ): Promise<any[]> {
    return this.get(fileName, presignedUrl);
  }

  static getInstance(): Storage {
    if (!Storage.instance) {
      Storage.instance = new Storage();
    }
    return Storage.instance;
  }
}
