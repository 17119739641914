export enum ApplicationErrorMessages {
  GENERIC_TITLE = "Sorry",
  GENERIC_SUBTITLE = "Application error",
  GENERIC = "Unexpected error.",
  INTERNET_CONNECTION = "Your device has no internet connection.",
  EMAIL_OR_PASSWORD_INVALID = "Invalid email or password.",
  EMAIL_INVALID = "Invalid email.",
  PASSWORD_INVALID = "Invalid password.",
  NOT_AUTHORIZED = "Incorrect email or password.",
  ACCESS_DENIED = "User access denied.",
  CLIENT = "There was an error in the request.",
  INTERNAL_SERVER = "Internal server error.",
  NETWORK = "Could not send request to server.",
  API_INTERNAL_ERROR = "API internal error.",
}

export enum FriendlyErrorMessages {
  GENERIC_TITLE = "Desculpe",
  GENERIC_SUBTITLE = "Aconteceu um erro na aplicação",
  GENERIC = "Aconteceu um erro que impossibilitou a visualização deste conteúdo.",
  INTERNET_CONNECTION = "Seu dispositivo não tem conexão com a internet.",
  EMAIL_OR_PASSWORD_INVALID = "Email ou senha inválido.",
  EMAIL_INVALID = "Email inválido.",
  PASSWORD_INVALID = "Senha inválida.",
  NOT_AUTHORIZED = "Email ou senha incorretos.",
  ACCESS_DENIED = "Usuário com acesso negado.",
  CLIENT = "Existe algum erro na solicitação.",
  INTERNAL_SERVER = "Erro interno do servidor.",
  NETWORK = "A solicitação não pôde ser enviada ao servidor.",
  API_INTERNAL_ERROR = "A aplicação não conseguiu processar a solicitação.",
}

export class FriendlyErrorInstructions {
  public static readonly generic = [
    "Tente novamente em instantes",
    "Se o problema persistir, entre em contato com o suporte técnico",
  ];
  public static readonly internetConnection = [
    "Veja se seu dispositivo está conectado à internet",
    "Verifique a qualidade da sua conexão",
    "Reinicie seu dispositivo",
    "Reinicie seu modem",
    ...FriendlyErrorInstructions.generic,
  ];
  public static readonly notAuthorized = [
    "Verifique se o Caps Lock está ligado",
    "Verifique se seu teclado está funcionando bem",
    ...FriendlyErrorInstructions.generic,
  ];
  public static readonly accessDenied = [FriendlyErrorInstructions.generic[1]];
  public static readonly client = FriendlyErrorInstructions.generic;
  public static readonly internalServer = FriendlyErrorInstructions.generic;
  public static readonly network = FriendlyErrorInstructions.generic;
  public static readonly apiInternalError = FriendlyErrorInstructions.generic;
}
