import { Plotly } from "./Plotly";
import BaseChartVisualLoader from "./BaseChartVisualLoader";
import { IChartVisualData } from "services/api";
import { PlotParams } from "react-plotly.js";
import theme from "views/Theme";

type TChartVisualDataContent = IChartVisualData["content"];

const parseContent = (content: TChartVisualDataContent) => {
  const plotly: PlotParams = JSON.parse(content.plotly);
  return {
    ...content,
    plotly: {
      ...plotly,
      layout: {
        ...plotly.layout,
        plot_bgcolor: theme.palette.primary.dark,
        paper_bgcolor: theme.palette.primary.dark,
      },
    },
    config: content.config,
  };
};

interface IBaseChartVisualProps {
  content?: TChartVisualDataContent;
  disable?: boolean;
}

export const BaseChartVisual = ({
  content,
  disable = false,
}: IBaseChartVisualProps) => {
  return !disable && content && content.plotly && content.config ? (
    <Plotly
      {...parseContent({ plotly: content.plotly, config: content.config })}
    />
  ) : (
    <BaseChartVisualLoader disable={disable} />
  );
};
