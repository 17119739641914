import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";

export const VisualEmpty = () => {
  return (
    <Box display="flex" px={2} py={4}>
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid
          container
          direction="row"
          justify="center"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item>
            <ErrorIcon fontSize={"large"} />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h2">Desculpe!</Typography>
              </Grid>
              <Box mt={1}>
                <Grid item>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    style={{ lineHeight: "20px" }}
                  >
                    Não há dados disponíveis para a consulta desejada.
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
